/*****************************************************************************
 *
 * 2020-2022 @ Quantinuum LLC.
 * This software and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 * All Rights Reserved.
 *
 *****************************************************************************/

import { Auth } from 'aws-amplify';
import moment from 'moment';
import { Hub } from 'aws-amplify';
import { AmplifyConfig } from '../config';

const getIdToken = async () => {
    //check if the session is still valid. If not we'll need to automatically sign the user out
    isSessionValid();

    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
};

const isSessionValid = async () => {
    await Auth.currentSession()
        .then((resp) => {})
        .catch((err) => {
            //session is not valid, automatically sign them out
            if (err.code === 'NotAuthorizedException') {
                handleNotAuthorized();
            }
        });
};

const handleNotAuthorized = () => {
    // sends a message to Amplify's Hub eventing system.
    const channel = hubChannels.auth; // the channel name to send a message to
    const event = 'signOut'; // this will tell App.jsx  to sign out after receiving message
    //data for building the toast that we want to display
    const data = {
        id: 'sessionExpired', // setting same toast id so we don't display duplicates
        title: 'Session Expired',
        severity: 'information',
    };
    const message = 'You were automatically signed out because your session expired.';

    sendHubMessage(channel, event, data, message);
};

const sendHubMessage = (channel, event, data, message) => {
    Hub.dispatch(channel, {
        event: event,
        data: data,
        message: message,
    });
};

const configureAuth = (auth) => {
    if (auth == undefined) {
        auth = '';
    }
    let config = AmplifyConfig;
    config['Auth']['authenticationFlowType'] = auth;
    // reconfigure the auth config for this authentication type
    Auth.configure(config);
};

const findLocalItems = (query) => {
    var i,
        results = [];
    for (i in sessionStorage) {
        if (sessionStorage.hasOwnProperty(i)) {
            if (i.includes(query) || (!query && typeof i === 'string')) {
                let value = JSON.parse(sessionStorage.getItem(i));
                results.push({ key: i, val: value });
            }
        }
    }
    return results;
};

const handleSignIn = async (response, username = '') => {
    if (response) {
        if (response.status === 200) {
            completeSignIn(response.data, username);
        } else if (response.status === 401) {
            customAuthSignIn(response.data);
        } else {
            returnToLogin();
        }
    } else {
        returnToLogin();
    }
};

//helper function also sign the user out of cognito
const signOutCognito = async () => {
    localStorage.clear();
    sessionStorage.clear();
    returnToLogin();
};

const completeSignIn = async (response, username = '') => {
    localStorage.setItem('isAuthenticated', 'true');
    localStorage.setItem('id-token', response['id-token']);
    localStorage.setItem('refresh-token', response['refresh-token']);

    if (username !== '') {
        localStorage.setItem('username', username);
    }
    window.location.replace('/user');
};

const returnToLogin = async () => {
    window.location.replace('/login');
};

const customAuthSignIn = async (response) => {
    //if we hit a challenge a custom challenge
    if (response['challenge-params'] !== undefined && response['challenge-name'] == authChallenges.custom) {
        let challengeParams = response['challenge-params'];

        let auth_challenge = challengeParams['auth-challenge'];

        let inviteId = '';

        //check if we're in the middle of a grace period
        let inGracePeriod = false;
        if (challengeParams['in-grace-period'] !== undefined) {
            inGracePeriod = challengeParams['in-grace-period'] == 'true';
            sessionStorage.setItem('in-grace-period', inGracePeriod);
        }

        let acceptedLatest = false;
        if (challengeParams['accepted-latest'] !== undefined) {
            acceptedLatest = challengeParams['accepted-latest'] == 'true';
            sessionStorage.setItem('accepted-latest', acceptedLatest);
        }

        let session = '';
        if ('session' in response && response['session'] !== undefined) {
            session = response['session'];
            sessionStorage.setItem('auth-session', session);
        }

        let signup = false;

        let software = getSoftwareFromChallenge(auth_challenge);
        if ('invite-id' in challengeParams && challengeParams['invite-id'] !== '' && software) {
            inviteId = challengeParams['invite-id'];
            sessionStorage.setItem('invite-id', inviteId);
            signup = true;
        }

        let upgrade = false;
        if ('upgrade' in challengeParams && challengeParams['upgrade'] !== undefined) {
            upgrade = challengeParams['upgrade'] == 'true';
            sessionStorage.setItem('upgrade', upgrade);
        }

        let route = '/terms';
        //if a signup event, then direct to that page
        if (signup) {
            route = '/software';
        }

        sessionStorage.setItem('challenge', response['challenge-params']['auth-challenge']);
        window.location.replace(route);
    } else if (response['challenge-name'] == authChallenges.totp) {
        window.location.replace('/mfa');
    }
};

const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise
            .then((val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)))
            .catch((error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)));
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

const permissionMap = {
    'org-admins': 'Organization Administrator',
    'hqs-admins': 'HQS Administrator',
    operators: 'Operator',
    user: 'User',
};

const getFriendlyPermission = (permission) => {
    return permissionMap[permission];
};

const getOriginalPermission = (friendlyPermission) => {
    return Object.keys(permissionMap).find((key) => permissionMap[key] === friendlyPermission);
};

const roundTwoDecimalPlaces = (numberValue) => {
    return Math.round((numberValue + Number.EPSILON) * 100) / 100;
};

const softwareDescMap = {
    inquanto: 'Quantum Chemistry Package (Python)',
};

const agreementTypes = {
    hardware: 'Hardware',
    inquanto: 'InQuanto',
    'inquanto-beta': 'InQuanto - Beta',
    'inquanto-trial': 'InQuanto - Trial',
};

const customerTypes = {
    reseller: 'Reseller (Default)',
    beta: 'Beta Tester',
    direct: 'Direct',
    trial: 'Trial',
};


const systemFamilies = {
    'HQS-LT': ['HQS-LT-S1', 'HQS-LT-S2'],
    'HQS-RT': ['HQS-RT-S1'],
    H1: ['H1-1', 'H1-2'],
    H2: ['H2-1', 'H2-2'],
    DEV: ['deadhead', 'operatordemo'],
    'UNIT-TEST': ['test-1', 'test-2', 'test-3'],
};

const orgTypes = {
    external: 'External',
    internal: 'Internal',
    azure: 'Azure'
}

const softwareDurationTypes = {
    inquanto: {
        reseller: { min: 0, max: 0, default: 0 },
        beta: { min: 0, max: 0, default: 0 },
        direct: { min: 0, max: 0, default: 0 },
        trial: { min: 0, max: 365, default: 30 },
    },
};

const licensedSoftwareTypes = ['inquanto'];

const softwareMap = {
    'inquanto': 'InQuanto'

}


const cognito = {
    attributes: {
        auth: 'custom:auth-challenge',
    },
};

const inquantoChallenges = {
    default: 'INQUANTO_EULA_REQUIRED',
    beta: 'INQUANTO_BETA_EULA_REQUIRED',
    exempt: 'INQUANTO_EULA_EXEMPT',
    trial: 'INQUANTO_TRIAL_EULA_REQUIRED',
};

const softwareChallenges = {
    inquanto: inquantoChallenges,
};

const softwareAuthChallenges = {
    inquanto: Object.values(inquantoChallenges),
};

const softwareCustomerTypes = {
    inquanto: {
        reseller: 'default',
        beta: 'beta',
        direct: 'exempt',
        trial: 'trial',
    },
};

const authChallenges = {
    none: '',
    custom: 'CUSTOM_CHALLENGE',
    np: 'NEW_PASSWORD_REQUIRED',
    tc: 'TERMS_AND_CONDITIONS_REQUIRED',
    exempt: 'TERMS_AND_CONDITIONS_EXEMPT',
    totp: 'SOFTWARE_TOKEN_MFA',
    software: softwareChallenges,
};

const agreementChallenges = [authChallenges.tc].concat(Object.values(inquantoChallenges));

const betaChallenges = [inquantoChallenges.beta];

const softwareDocs = {
    inquanto: {
        reseller: 'https://inquanto.quantinuum.com',
        beta: 'https://inquantobetadocs.azurewebsites.net/',
        direct: 'https://inquanto.quantinuum.com',
        trial: 'https://inquanto.quantinuum.com',
    },
};

const eula = {
    inquanto: {
        INQUANTO_EULA_REQUIRED: {
            link: 'https://www.quantinuum.com/computationalchemistry/inquanto/eula',
            desc: 'End User License Agreement (EULA)',
        },
        INQUANTO_BETA_EULA_REQUIRED: {
            link: '/InQuanto_Beta_Testing_EULA.pdf',
            desc: 'Beta End User License Agreement (EULA)',
        },
        INQUANTO_TRIAL_EULA_REQUIRED: {
            link: '/InQuanto_Trial_EULA.pdf',
            desc: 'Trial End User License Agreement (EULA)',
        },
        INQUANTO_EULA_EXEMPT: {
            link: 'https://www.quantinuum.com/computationalchemistry/inquanto/eula',
            desc: '',
        },
    },
};

const hubChannels = {
    auth: 'AUTH_CHANNEL',
};

// catergories defining impact for terms and conditions
// category 1 - disruptive
// category 2 - non-disruptive
const category = {
    none: undefined,
    one: 1,
    two: 2,
};

const recoveryActions = {
    forgotPwd: 'forgotPassword',
    forgotMfa: 'forgotMfa',
    confirmPwd: 'confirmPassword',
    confirmMfa: 'confirmMfa',
};

const maxTags = 3;
const maxTagKeyLength = 25;
const maxTagValueLength = 25;
const maxTagDescLength = 50;
const maxTagAllowedValues = 10;

const isoFormats = {
    default: 'YYYY-MM-DD HH:mm:ss',
    detailed: 'YYYY-MM-DD HH:mm:ss.SSS',
    short: 'YYYY-MM-DD',
};

const getISOFormat = (format) => {
    /* determine the corresponding iso date format from incoming string*/
    let isoFormat = '';
    if (format && isoFormats.hasOwnProperty(format)) {
        isoFormat = isoFormats[format];
    } else {
        isoFormat = isoFormats.default;
    }
    return isoFormat;
};

function toISODate(date, format, timeZone) {
    /* format a date string in ISO 8601 formatting*/
    let isoFormat = getISOFormat(format);

    if (date !== undefined && date !== '' && isoFormat) {

        var offset = moment().utcOffset();
        if (timeZone){
            offset = moment().tz(timeZone).utcOffset();
        }

        format = moment.utc(date).utcOffset(offset).format(isoFormat);
    }

    return format;
}

function toLocalTime(date) {
    let localTime = date;

    if (date !== undefined && date !== '') {
        let offset = moment().utcOffset;
        localTime = moment.utc(date).utcOffset(offset).format();
    }

    return localTime;
}


const getFriendlySoftwareDescription = (software) => {
    return softwareDescMap[software];
};

const getSoftwareDurationByCustomer = (software, customerType, field) => {
    //make sure we have software and customer type and the software name is valid (if 'none' is selected, it'll fall back to 0)
    if(software && customerType && isLicensedPlan(software)){
        return softwareDurationTypes[software][customerType][field]
    }
    return 0
};


const getSystemFamily = (machineName) => {
    //get the family name this machine belongs to
    for (const [family, machines] of Object.entries(systemFamilies)) {
        if (machines.includes(machineName)) {
            return family;
        }
    }

    return undefined;
};

//check if an array contains all the elements of a target array
const checker = (arr, target) => target.every((v) => arr.includes(v));

const getAccessibleSystemFamilies = (allMachines) => {
    let families = [];
    for (const [family, machines] of Object.entries(systemFamilies)) {
        //check if the current list of machines contains all the machines in the given family
        if (checker(allMachines, machines)) {
            families.push(family);
        }
    }

    return families;
};

const isMachineFamily = (familyName) => {
    if (systemFamilies[familyName] !== undefined && systemFamilies[familyName].length > 0) {
        return true;
    } else {
        return false;
    }
};

function isEmulator(machine) {
    //checks is a given machine name is a simulator/emulator
    if (
        machine.toLowerCase().includes('sim') ||
        (machine.toLowerCase().includes('-') && machine.toLowerCase().endsWith('e'))
    ) {
        return true;
    } else {
        return false;
    }
}




function isLicensedPlan(software) {
    if (software && licensedSoftwareTypes.includes(software)) {
        return true;
    }

    return false;
}

function isSoftwareChallenge(authChallenge) {
    let isSoftwareChallenge = false;

    let software = getSoftwareFromChallenge(authChallenge);

    //if we found an associated software with this challenge, then we know it's a valid challenge
    if (software) {
        isSoftwareChallenge = true;
    }

    return isSoftwareChallenge;
}

function isBetaChallenge(authChallenge) {
    let isBeta = false;

    if (betaChallenges.includes(authChallenge)) {
        isBeta = true;
    }

    return isBeta;
}

function isExempt(challenges) {
    let exempt = false;

    if (challenges && challenges.includes(authChallenges.exempt)) {
        exempt = true;
    }

    return exempt;
}

function getEula(software, authChallenge) {
    if (isLicensedPlan(software)) {
        let origin = window.location.origin;
        return origin + eula[software][authChallenge]['link'];
    }
    return '';
}

function getEulaDescription(software, authChallenge) {
    let desc = '';
    if (isLicensedPlan(software)) {
        desc = eula[software][authChallenge]['desc'];
    }
    return desc;
}

function getSoftwareFromChallenge(authChallenge) {
    let software = '';

    if (authChallenge) {
        for (const [key, challenges] of Object.entries(softwareAuthChallenges)) {
            if (challenges.includes(authChallenge)) {
                software = key;
                break;
            }
        }
    }

    return software;
}

function getSoftwareCustomerChallenge(software, customer) {
    let challenge = '';
    if (isLicensedPlan(software)) {
        let challengeType = softwareCustomerTypes[software][customer];

        challenge = softwareChallenges[software][challengeType];
    }
    return challenge;
}

/*helper functions to build the filters*/

const getFilterableOrgUsers = (orgUsers) => {
    let users = [];
    if (orgUsers !== undefined) {
        orgUsers.forEach((u) => {
            users = users.concat(u.email);
        });
        users.sort((a, b) => a.localeCompare(b));
    }
    return users;
};

const getFilterableUserGroups = (userGroups) => {
    let groups = [];
    if (userGroups !== undefined && userGroups.length > 0) {
        userGroups.forEach((u) => {
            if (u.name !== undefined) {
                groups = groups.concat(u.name);
            } else {
                groups = groups.concat(u);
            }
        });
        groups.sort((a, b) => a.localeCompare(b));
    }
    return groups;
};

const getFilterableOrgMachines = (orgPlans, validMachines) => {
    let machines = [];

    if (orgPlans !== undefined) {
        //check each plan to collect the machines available for this org
        orgPlans.forEach((p) => {
            machines = machines.concat(p.machine_name);
        });

        //determine which machines we can actually filter on (valid machines are the ones found on the machines tab)
        machines = getFilterableMachines(machines, validMachines);
    }

    return machines;
};

const getFilterableMachines = (allMachines, validMachines) => {
    let machines = [];

    if (allMachines !== undefined) {
        //check each machine and see it's a valid one (on the machines tab)
        allMachines.forEach((m) => {
            if (isActiveMachine(validMachines, m)) {
                machines = machines.concat(m);
            }
        });

        //try to add any system familes this user belongs too. If they don't have all machines in family, it won't be added.
        let families = getAccessibleSystemFamilies(machines);
        if (families && families.length > 0) {
            machines = machines.concat(families);
        }

        machines.sort((a, b) => a.localeCompare(b));
    }

    return machines;
};

const getTotalCost = (jobs, status) => {
    //if we have a status, filter on that first  before processing jobs
    if (status) {
        jobs = jobs.filter((j) => j['status'] === status);
    }
    const sum = jobs.reduce(function (x, y) {
        //get the cost if we have a cost, otherwise it's 0
        let cost = y['cost'] ? y['cost'] : 0;
        //round here so we adding what's being rendered on screen
        return x + roundTwoDecimalPlaces(cost);
    }, 0);
    //round one more time to ensure correctness
    return roundTwoDecimalPlaces(sum);
};

const isActiveMachine = (machines, machine) => {
    let isActive = false;
    if (machines) {
        let match = machines.filter(function (e) {
            return e == machine || e.name == machine;
        });
        //if we found a match or if we didnt', but it's actually a family name.
        if (match && match[0] !== undefined) {
            isActive = true;
        } else {
            if (isMachineFamily(machine)) {
                isActive = true;
            }
        }
    }
    return isActive;
};

const sortObjectByKeys = (o) => {
    //sort the object key it's keys alphabetically, while ignoring case
    return Object.keys(o)
        .sort((a, b) => a.localeCompare(b))
        .reduce((r, k) => ((r[k] = o[k]), r), {});
};

const sortFiltersByKeys = (filters) => {
    if (!isNullOrEmpty(filters)) {
        //don't filter on certain filters because we like the default order (statuses, priorities)
        let exclude = ['statuses', 'priorities'];

        Object.keys(filters).forEach((f) => {
            //check if we can sort on this field/key
            if (!exclude.includes(f)) {
                //sort the contents of this filter by it's keys
                filters[f] = sortObjectByKeys(filters[f]);
            }
        });
    }

    return filters;
};

const passwordPolicy = {
    minLength: 8,
    upperCase: true,
    lowerCase: true,
    number: true,
};

const jobEndStates = ['completed', 'canceled', 'failed', 'canceling'];

const jobFilterStates = [
    'completed',
    'running',
    'failed',
    'canceled',
    'queued',
    'pending',
    'canceling',
    'estimated',
    'estimating',
];

const jobFilterPriorities = ['normal', 'low', 'high', 'background', 'reserved'];

const validJson = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
};

const fileExtensionMap = {
    py: 'python',
    java: 'java',
    js: 'javascript',
    jsx: 'jsx',
    sh: 'bash',
    json: 'json',
    go: 'go',
    cpp: 'cpp',
    c: 'c',
    ipynb: 'ipynb',
    html: 'html',
    md: 'markup',
    markup: 'markup',
    pdf: 'pdf',
    whl: 'whl',
};

const modes = {
    user: 'user',
    orgAdmin: 'org',
    operator: 'operator',
    hqsAdmin: 'admin',
};

const jobParams = {
    statuses: 'status',
    machines: 'machine',
    priorities: 'priority',
    users: 'email',
    orgs: 'org',
    groups: 'group',
};

const notParams = {
    status: 'status',
    machine: 'machine',
    priority: 'priority',
    email: 'user', // different
    org: 'org',
    group: 'group',
};

const auditParams = {
    actions: 'action',
    requestors: 'requestor'
};

const notAuditParams = {
    action: 'action',
    requestor: 'requestor'
};
const isElevatedViewMode = (mode) => {
    // checks if the current mode should have access to elevated views
    return mode == modes.hqsAdmin || mode == modes.operator;
};

const isNullOrEmpty = (obj) => {
    let isEmpty = true;
    if (obj !== null && obj !== undefined) {
        isEmpty = Object.keys(obj).every((x) => JSON.stringify(obj[x]) === '{}');
    }
    return isEmpty;
};

const generateFilters = (jobs, filters, mode) => {
    //if null, let's fall back on a default value
    if (!filters || isNullOrEmpty(filters)) {
        filters = {
            machines: {},
            statuses: {},
            priorities: {},
            users: {},
            orgs: {},
            groups: {},
        };
    }

    /*always include all status options*/
    jobFilterStates.forEach((s) => {
        if (!filters.hasOwnProperty('statuses')) {
            filters['statuses'] = {};
        }
        filters.statuses[s] = true;
    });
    if (isElevatedViewMode(mode)) {
        // make sure priorities is there
        if (!filters.hasOwnProperty('priorities')) {
            filters['priorities'] = {};
        }

        jobFilterPriorities.forEach((p) => {
            filters.priorities[p] = true;
        });
    }

    if (jobs && mode) {
        //only generate once when we got the first unfiltered data. If we're changing pages we shouldn't have to do this.
        jobs.forEach((jj) => {
            filters.machines[jj.machine] = true;
            //this will add any statuses that are on the job (possibly if any are missed in 'jobFilterStates' constant)
            filters.statuses[jj.status] = true;

            //handle undefined (rename as none)
            if (jj.group == undefined || jj.group == 'undefined') {
                filters.groups['none'] = true;
            } else {
                filters.groups[jj.group] = true;
            }
            //only allow filtering on user if we aren't in user mode
            if (mode != modes.user) {
                filters.users[jj.user] = true;
            }
            if (isElevatedViewMode(mode)) {
                filters.orgs[jj.org] = true;
                //this will add any priorities that are on the job (possibly if any are missed in 'jobFilterPriorities' constant)
                filters.priorities[jj.priority] = true;
            }
        });
    }

    return filters;
};


const generateAuditFilters = (logs, filters, mode) => {
    //if null, let's fall back on a default value
    if (!filters || isNullOrEmpty(filters)) {
        filters = {
            actions: {},
            requestors: {},
        };
    }

    

    if (logs && mode) {
        //only generate once when we got the first unfiltered data. If we're changing pages we shouldn't have to do this.
        logs.forEach((ll) => {
            filters.actions[ll.action] = true;
            //this will add any statuses that are on the job (possibly if any are missed in 'jobFilterStates' constant)
            filters.requestors[ll.requestor] = true;

        });
    }

    return filters;
};

const updateJobQueryParams = (queryParams, filters) => {
    if (filters) {
        //check if filter (statuses, machines, users, etc.)
        Object.keys(filters).forEach((k) => {
            //isolate the specific filter (example {"statuses": 'completed': true|false})
            let selectedFilters = filters[k];

            if (!isNullOrEmpty(selectedFilters)) {
                //only filters that are currently active/turned on.
                let active = Object.keys(selectedFilters).filter(
                    (s) => selectedFilters[s] === false && s !== allFilterSelections[k],
                );

                //convert the active filters be be used in the query string
                let values = [...active].join(',');

                //only bother updating the query params if we have any values
                if (values.length > 0) {
                    //lookup the expected query param for the given key since the pluralization and naems are slightly different
                    let p = jobParams[k];
                    //update the query string with the new filter criteria
                    queryParams[p] = values;
                    if (queryParams['not'] !== undefined) {
                        queryParams['not'] += ',' + notParams[p];
                    } else {
                        queryParams['not'] = notParams[p];
                    }
                }
            }
        });
    }

    return queryParams;
};

const updateAuditQueryParams = (queryParams, filters) => {
    if (filters) {
        //check if filter (statuses, machines, users, etc.)
        Object.keys(filters).forEach((k) => {
            //isolate the specific filter (example {"statuses": 'completed': true|false})
            let selectedFilters = filters[k];

            if (!isNullOrEmpty(selectedFilters)) {
                //only filters that are currently active/turned on.
                let active = Object.keys(selectedFilters).filter(
                    (s) => selectedFilters[s] === false && s !== allAuditFilterSelections[k],
                );

                //convert the active filters be be used in the query string
                let values = [...active].join(',');

                //only bother updating the query params if we have any values
                if (values.length > 0) {
                    //lookup the expected query param for the given key since the pluralization and naems are slightly different
                    let p = auditParams[k];
                    //update the query string with the new filter criteria
                    queryParams[p] = values;
                    if (queryParams['not'] !== undefined) {
                        queryParams['not'] += ',' + notAuditParams[p];
                    } else {
                        queryParams['not'] = notAuditParams[p];
                    }
                }
            }
        });
    }

    return queryParams;
};

const deleteParams = (queryParams, keys) => {
    //delete multiple keys from the query params and return updated object
    keys.forEach((k) => {
        if (queryParams.hasOwnProperty(k)) {
            delete queryParams[k];
        }
    });
    return queryParams;
};

const updateSearchParams = (queryParams, search, exclude = null) => {
    //if job Id was passed in simplify the request
    if (search !== null && search != '') {
        if (exclude != null) {
            // safely delete the unnecessary params and update the overall query params
            queryParams = deleteParams(queryParams, exclude);
        }

        // map the search text to available parameters
        queryParams.search = search;
    }

    return queryParams;
};

const filterJobs = (jobs, filters) => {
    let statuses = new Set();
    let machines = new Set();
    let priorities = new Set();
    let users = new Set();
    let orgs = new Set();
    let groups = new Set();

    //only filter if it exists
    if (filters && !isNullOrEmpty(filters)) {
        //check if filter (statuses, machines, users, etc.)
        if ('statuses' in filters) {
            statuses = new Set(Object.keys(filters.statuses).filter((s) => filters.statuses[s] === true));
        }
        if ('machines' in filters) {
            machines = new Set(Object.keys(filters.machines).filter((s) => filters.machines[s] === true));
        }
        if ('priorities' in filters) {
            priorities = new Set(Object.keys(filters.priorities).filter((s) => filters.priorities[s] === true));
        }
        if ('users' in filters) {
            users = new Set(Object.keys(filters.users).filter((s) => filters.users[s] === true));
        }
        if ('orgs' in filters) {
            orgs = new Set(Object.keys(filters.orgs).filter((s) => filters.orgs[s] === true));
        }

        if ('groups' in filters) {
            groups = new Set(Object.keys(filters.groups).filter((s) => filters.groups[s] === true));
        }

        //apply the filters
        jobs = jobs.filter((jj) => {
            if (statuses.size > 0 && !statuses.has(jj.status)) return false;
            if (machines.size > 0 && !machines.has(jj.machine)) return false;
            if (priorities.size > 0 && !priorities.has(jj.priority)) return false;
            if (users.size > 0 && !users.has(jj.user)) return false;
            if (orgs.size > 0 && !orgs.has(jj.org)) return false;
            if (groups.size > 0 && !groups.has(jj.groups)) return false;
            return true;
        });
    }

    return jobs;
};

const isVersionNumber = (version) => {
    let valid = true;
    const validVersion = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
    //validate the version number
    if (!validVersion.test(version)) {
        valid = false;
    }
    return valid;
};

const getVersionCategory = (version) => {
    // determine the impact category for terms and conditions
    //1, 1.0, 1.0.0 = category 1
    //1.1, 1.2, 1.2.5 = category 2
    let cat = category.none;
    //assume cat 1
    if (version !== undefined && version !== '') {
        //assume it'll be category 1
        cat = category.one;
        let versions = version.split('.').map((v) => Number(v));
        if (versions.length > 1) {
            let subversions = versions.slice(1);
            let allZeros = subversions.every((v) => {
                return v == 0;
            });

            if (!allZeros) {
                cat = category.two;
            }
        }
    }

    return cat;
};

const isValidEmail = (email) => {
    /*

    Validation Rules: https://en.wikipedia.org/wiki/Email_address
    This passes the majority of the valid/invalid emails on this page

    Of the example valid email addresses, this regex passes all except:

    - (space between the quotes)
    - (quoted double dot)
    - (include non-letters character AND multiple at sign, the first one being double quoted)
    - (IP addresses are allowed instead of domains when in square brackets, but strongly discouraged)
    - (IPv6 uses a different syntax)

    Of the example invalid email addresses, this regex passes all of them.

    Local Part validation
    - uppercase and lowercase Latin letters A to Z and a to z
    - digits 0 to 9
    - printable characters !#$%&'*+-/=?^_`{|}~
    - dot ., provided that it is not the first or last character and provided also that it does not appear consecutively (e.g., John..Doe@example.com is not allowed).[5]
    - maximum of 64 chars

    Domain Part Validation:

    - uppercase and lowercase Latin letters A to Z and a to z;
    - digits 0 to 9, provided that top-level domain names are not all-numeric;
    - hyphen -, provided that it is not the first or last character.
    - maximum of 255 chars

    */
    let localMax = 64;
    let domainMax = 255;
    let emailFormat =
        /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)(@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)/;

    email = email.trim().toLowerCase();
    let isEmail = emailFormat.test(email);

    if (isEmail) {
        //validate the length
        let matches = email.match(emailFormat);

        if (matches && matches.length >= 2) {
            //1 is the local part
            let local = matches[1];
            //2 is the domain part
            let domain = matches[2];

            //check lengths
            if (local.length > localMax || domain.length > domainMax) {
                isEmail = false;
            }
        }
    }

    return isEmail;
};

const arrayRange = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

const allFilterSelections = {
    machines: 'All Machines',
    statuses: 'All Statuses',
    priorities: 'All Priorities',
    users: 'All Users',
    orgs: 'All Orgs',
    groups: 'All Groups',
};

const allAuditFilterSelections = {
    actions: 'All Actions',
    requestors: 'All Requestors'
};


export {
    getIdToken,
    makeCancelable,
    getFriendlyPermission,
    getOriginalPermission,
    getFriendlySoftwareDescription,
    roundTwoDecimalPlaces,
    validJson,
    isElevatedViewMode,
    isNullOrEmpty,
    updateJobQueryParams,
    updateAuditQueryParams,
    generateFilters,
    generateAuditFilters,
    filterJobs,
    getSystemFamily,
    isMachineFamily,
    isLicensedPlan,
    isSoftwareChallenge,
    isBetaChallenge,
    getSoftwareFromChallenge,
    getSoftwareCustomerChallenge,
    isExempt,
    getEula,
    getEulaDescription,
    isEmulator,
    isValidEmail,
    getAccessibleSystemFamilies,
    getFilterableOrgUsers,
    getFilterableOrgMachines,
    getFilterableMachines,
    getFilterableUserGroups,
    sortFiltersByKeys,
    isVersionNumber,
    toISODate,
    toLocalTime,
    getVersionCategory,
    updateSearchParams,
    configureAuth,
    findLocalItems,
    signOutCognito,
    handleSignIn,
    getTotalCost,
    arrayRange,
    softwareDurationTypes,
    jobEndStates,
    fileExtensionMap,
    passwordPolicy,
    modes,
    cognito,
    authChallenges,
    hubChannels,
    recoveryActions,
    maxTags,
    maxTagKeyLength,
    maxTagValueLength,
    maxTagDescLength,
    maxTagAllowedValues,
    allFilterSelections,
    agreementTypes,
    agreementChallenges,
    softwareChallenges,
    customerTypes,
    softwareDocs,
    isoFormats,
    softwareMap,
    getSoftwareDurationByCustomer,
    orgTypes
};
