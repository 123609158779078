/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2023 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React, { Component, useState } from 'react';
import * as HQS_API from '../utils/api';
import { modes } from '../utils/helpers';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import moment from 'moment-timezone';
import './OrgAdmin.css';
import CreateUserForm from '../Forms/CreateUser';
import AddUserGroupForm from '../Forms/AddUserGroup';
import CalendarTab from '../Event/CalendarTab';
import { JobsTab } from '../Jobs/JobsTab';
import {
    getFriendlyPermission,
    roundTwoDecimalPlaces,
    isNullOrEmpty,
    updateJobQueryParams,
    generateFilters,
    getFilterableOrgUsers,
    getFilterableOrgMachines,
    getFilterableUserGroups,
    sortFiltersByKeys,
    updateSearchParams,
    toISODate,
    fileExtensionMap,
    softwareMap,
    customerTypes,
    isLicensedPlan,
} from '../utils/helpers';
import { getRefreshSchedule } from '../utils/anyPlan';
import ReportsTab from '../Reports/ReportsTab';
import { Tab, Icon, Button } from '@scuf/common';
import { DataTable } from '@scuf/datatable';
import { ToastContainer } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import UserGroupActionSelect from '../Other/UserGroupActionSelect';
import OrgUserActionSelect from '../Other/OrgUserActionSelect';
import BatchRequests from '../Batch/BatchRequests';
import UserGroupQuota from '../Quota/UserGroupQuota';
import { toast } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import { GenerateUserGroupUsageReportForm } from '../Forms/GenerateUserGroupUsageReport';
import TreeView from '../user/Treeview';
import CodeViewer from '../user/CodeViewer';
import { APIExamplesBucket } from '../config';
import JSZip from 'jszip';
import saveAs from 'file-saver';

const AWS = window.AWS;
class OrgAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userDetails: {},
            users: [],
            orgDetails: {},
            userGroups: [],
            orgLicenses: [],
            userGroupExpandedRows: [],
            userExpandedRows: [],
            orgPlans: [],
            allMachines: [],
            jobs: [],
            reports: [],
            fetchingJobs: false,
            fetchingUsers: false,
            fetchingUserGroups: false,
            fetchingJobsReport: false,
            selectedFileData: '',
            selectedFileFormat: 'none',
            selectedFileURL: '',
            userGroupSelectedRow: null,
            orgUserSelectedRow: null,
            mode: 'org',
            orgList: [],
            fetchingReports: false,
            page: {
                size: 25,
                number: 1,
                keys: { 1: null },
                visited: new Set([1]),
                reverse: true,
                search: null,
                sort: 'submit-date',
            },
            filters: {
                machines: { 'All Machines': true },
                statuses: { 'All Statuses': true },
                users: { 'All Users': true },
                groups: { 'All Groups': true },
            },
            search: null,
        };

        this.blobRef = React.createRef();
        this.update = this.update.bind(this);
        this.getOrgJobs = this.getOrgJobs.bind(this);
        this.groupNamesRenderer = this.groupNamesRenderer.bind(this);
        this.groupNameRenderer = this.groupNameRenderer.bind(this);
        this.dateRenderer = this.dateRenderer.bind(this);
        this.shortDateRenderer = this.shortDateRenderer.bind(this);
        this.getGroupName = this.getGroupName.bind(this);
        this.hqcRenderer = this.hqcRenderer.bind(this);
        this.calculateCreditsAvailable = this.calculateCreditsAvailable.bind(this);
        this.getUserGroups = this.getUserGroups.bind(this);
        this.afterUserGroupAdd = this.afterUserGroupAdd.bind(this);
        this.handleUserGroupRowSelect = this.handleUserGroupRowSelect.bind(this);
        this.handleOrgUserRowSelect = this.handleOrgUserRowSelect.bind(this);
        this.getUsersOfOrganization = this.getUsersOfOrganization.bind(this);
        this.getMachines = this.getMachines.bind(this);
        this.getOrgDetails = this.getOrgDetails.bind(this);
        this.userExpanderTemplate = this.userExpanderTemplate.bind(this);
        this.userGroupExpanderTemplate = this.userGroupExpanderTemplate.bind(this);
        this.toggleUserExpand = this.toggleUserExpand.bind(this);
        this.toggleUserGroupExpand = this.toggleUserGroupExpand.bind(this);
        this.userGroupExpansionTemplate = this.userGroupExpansionTemplate.bind(this);
        this.userExpansionTemplate = this.userExpansionTemplate.bind(this);
        this.fetchJobsReport = this.fetchJobsReport.bind(this);
        this.fetchReports = this.fetchReports.bind(this);
        //AWS helper functions
        this.getAsyncNodes = this.getAsyncNodes.bind(this);
        this.getNodeName = this.getNodeName.bind(this);
        this.getObject = this.getObject.bind(this);
        this.getExamplesAccess = this.getExamplesAccess.bind(this);
        this.handleExamplesDownload = this.handleExamplesDownload.bind(this);
    }

    componentDidMount() {
        this.getUserDetails(this.getUsersOfOrganization);
        this.getMachines();
        this.getExamplesAccess();
    }

    update() {
        this.getUsersOfOrganization();
        this.setState({ orgUserSelectedRow: null });
        this.getOrgDetails();
    }

    getUserDrivenDetails() {
        this.getOrgDetails();
        this.getUserGroups();
        this.fetchReports();
    }

    afterUserGroupAdd() {
        this.getUserGroups();
        // afterUserGroupAdd also is called after deleting a group
        // so we need to refresh users in case one of their groups got deleted
        this.getUsersOfOrganization();
        this.setState({ userGroupSelectedRow: null });
    }

    async fetchJobsReport(filename, start, end, status, org) {
        this.setState({ fetchingJobsReport: true });

        let queryParams = {
            start: start,
            end: end,
            status: status,
            org: org,
        };

        HQS_API.jobReporting(queryParams)
            .then((response) => {
                if (typeof this.blobRef.current === 'object') {
                    const blob = new Blob([response], { type: 'text/csv' });
                    if (blob.size > 2) {
                        const url = URL.createObjectURL(blob);
                        this.blobRef.current.href = url;
                        this.blobRef.current.download = filename;
                        this.blobRef.current.click();
                        URL.revokeObjectURL(url);

                        const title = 'Successfully generated report';
                        const details = filename;
                        toast(<ToastNotification closeToast={false} title={title} details={details} />);
                    } else {
                        const title = 'Unable to generate report';
                        const details = 'No records found';
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="information"
                            />,
                        );
                    }
                }
            })
            .catch((error) => {
                const title = 'Unable to generate report';
                const details = 'Access denied';
                toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
            })
            .finally(() => {
                this.setState({ fetchingJobsReport: true });
            });
    }

    async getMachines() {
        HQS_API.listMachines()
            .then((response) => {
                let allMachines = [];
                response.forEach((machineName) => {
                    let machine = {
                        name: machineName,
                        description: machineName,
                        enabled: false,
                    };

                    allMachines.push(machine);
                });
                allMachines.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({ allMachines: allMachines });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getOrgJobs(org, start, end, page, filters, search) {
        this.setState({ fetchingJobs: true });

        let startTemp = moment(start).startOf('day');
        let endTemp = moment(end).endOf('day');
        start = moment.utc(startTemp).format('YYYY-MM-DD[T]HH:mm:ss');
        end = moment.utc(endTemp).format('YYYY-MM-DD[T]HH:mm:ss');

        let queryParams = {
            mode: 'org',
            org: this.state.userDetails.organization,
            start: start,
            end: end,
        };

        //ensure we either have the default page or the passed in page information
        if (page == undefined) {
            page = this.state.page;
        }

        //add limit to parameters if it's a positive number
        if (page.size > 0) {
            queryParams.limit = page.size;
        }

        let header_key = undefined;
        //add key to parameters if we know what it is. If we don't pass a key it will default to the first page
        if (page.number > 1 && page.keys !== undefined) {
            header_key = page.keys[page.number];
        }

        //check if we need to fetch data in ascending order
        if (page.reverse == false) {
            queryParams.reverse = page.reverse;
        }

        // check how we're sorting this data
        if (page.sort) {
            queryParams.sort = page.sort;
        }

        //if search was passed in, then prepare the request
        if (search !== null && search != '') {
            // remove the org. The job Id there searching for could be for any org.
            let exclude = ['start', 'end'];
            // update the query params with the search criteria
            queryParams = updateSearchParams(queryParams, search, exclude);

            //assign the page the job Id we searched on
            page.search = search;
        }

        //check if we at least something to filter on
        if (!isNullOrEmpty(filters)) {
            //optionally add other filters selected after initially query
            queryParams = updateJobQueryParams(queryParams, filters);
        }

        HQS_API.listJobs(queryParams, header_key)
            .then((response) => {
                let jobs = response['items'];
                let pageNext = undefined;
                //assign the next page reference
                if (response['key'] !== undefined) {
                    pageNext = response['key'];
                }

                //update our page keys with pointer to next page
                page.keys[page.number + 1] = pageNext;

                //keep track of where we've been
                if (page.visited && !page.visited.has(page.number)) {
                    page.visited.add(page.number);
                }

                //hold on to the current state of the filters.
                let currentFilters = this.state.filters;
                //if the incoming filters are empty (first API call, refresh, filter reset) and we're still on the first page (meaning we aren't paging to subsequent pages)                //then regenerate
                //then regenerate
                if (isNullOrEmpty(filters) || page.number == 1) {
                    currentFilters = generateFilters(jobs, this.state.filters, this.state.mode);

                    //dynamically set the machines based on what this org has access to.
                    //will fill in gaps the first page won't have
                    getFilterableOrgMachines(this.state.orgPlans, this.state.allMachines).forEach((m) => {
                        currentFilters.machines[m] = true;
                    });

                    getFilterableOrgUsers(this.state.users).forEach((u) => {
                        currentFilters.users[u] = true;
                    });

                    getFilterableUserGroups(this.state.userGroups).forEach((g) => {
                        currentFilters.groups[g] = true;
                    });

                    //sort the filters contents in case it isn't sorted already
                    currentFilters = sortFiltersByKeys(currentFilters);
                }

                this.setState({
                    jobs: jobs,
                    page: page,
                    filters: currentFilters,
                });
            })
            .catch((error) => {
                console.log(error);
                // if there's an actual error code, display that message
                if (error.hasOwnProperty('code')) {
                    //catch any other error and display the message to user
                    const title = error.code;
                    const details = error.message;
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                } else {
                    //it's a timeout
                    const title = 'Search timed out';
                    const details = 'Please narrow down your search criteria and try again.';
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                }
            })
            .finally(() => {
                this.setState({ fetchingJobs: false, search: search });
            });
        this.setState({ fetchingJobs: true });
    }

    async getOrgDetails() {
        HQS_API.getOrganization(this.state.userDetails.organization, 'org')
            .then((response) => {
                let orgPlans = response['plans'];

                let orgPlansMapped = [];

                orgPlans.forEach((plan) => {
                    let newPlan = {
                        id: plan['id'],
                        enabled: plan['enabled'],
                        unlimited: 'unlimited' in plan && plan['unlimited'] ? ['unlimited'] : false,
                        available_hqc: 'available_hqc' in plan ? plan['available_hqc'] : 0,
                        pending_hqc: 'pending_hqc' in plan ? plan['pending_hqc'] : 0,
                        create_date: 'create_date' in plan ? plan['create_date'] : '',
                        activation_date: 'activation_date' in plan ? plan['activation_date'] : '',
                        refresh_start_date: 'refresh_start_date' in plan ? plan['refresh_start_date'] : '',
                        refresh_enabled: 'refresh_enabled' in plan ? plan['refresh_enabled'] : false,
                        deactivation_date: 'deactivation_date' in plan ? plan['deactivation_date'] : '',
                        //need to agree on correct machine name convention
                        machine_name: plan['machine_name'],
                        software: plan['software'],
                        raw: plan, //the full object in case we need to acces more properties later
                    };

                    //might be temporary
                    if (newPlan['machine_name'] == undefined) {
                        newPlan['machine_name'] = {};
                    }
                    orgPlansMapped.push(newPlan);
                });

                let orgLicenses = [];
                orgPlansMapped.sort((a, b) => {
                    //sort on the first machine in the plan (since we know it's fetched alphabetically)
                    if ('machine_name' in a && 'machine_name' in b) {
                        if (
                            Object.keys(a['machine_name'])[0].toLowerCase() <
                            Object.keys(b['machine_name'])[0].toLowerCase()
                        )
                            return -1;
                        if (
                            Object.keys(a['machine_name'])[0].toLowerCase() >
                            Object.keys(b['machine_name'])[0].toLowerCase()
                        )
                            return 1;
                        return 0;
                    }
                });

                // add the license information so we can dynamically update the software for this org
                if ('licenses' in response) {
                    var newOrgLicense = {
                        name: this.state.userDetails.organization,
                        licenses: 'licenses' in response ? response['licenses'] : [],
                    };
                    orgLicenses.push(newOrgLicense);
                }
                this.setState({
                    orgDetails: response,
                    orgPlans: orgPlansMapped,
                    orgLicenses: orgLicenses,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getUserDetails(callback) {
        HQS_API.getSelfUser()
            .then((response) => {
                this.setState(
                    {
                        userDetails: {
                            first_name: response['first-name'],
                            last_name: response['last-name'],
                            email: response['email'],
                            phone_number: response['phone-number'],
                            organization: response['organization'],
                        },
                        expandedUsers: new Set(),
                        orgList: [response['organization']],
                    },
                    this.getUserDrivenDetails,
                );
                callback.bind(this)(this.state.userDetails.organization);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getUsersOfOrganization() {
        HQS_API.getUsers({ organization: this.state.userDetails.organization })
            .then((response) => {
                let newUsers = [];
                const offset = moment().utcOffset();
                response.forEach((user) => {
                    let createDate = 'create-date' in user ? user['create-date'] : '';
                    var permissions = '';
                    user['groups'].forEach((permission, index) => {
                        let commaString = index <= user['groups'].length - 2 ? ', ' : '';
                        permissions += getFriendlyPermission(permission) + commaString;
                    });

                    var software = '';

                    if ('software' in user && Array.isArray(user['software'])) {
                        var allSoftware = '';
                        user['software'].forEach((s, index) => {
                            let commaString = index <= user['software'].length - 2 ? ', ' : '';
                            allSoftware += softwareMap[s] + commaString;
                        });

                        software = allSoftware;
                    }

                    let userGroups = '';
                    let quota = user['quota-enabled'] ? user.quota : 0;
                    let quotas = 'quotas' in user ? user['quotas'] : [];
                    userGroups = 'user-groups' in user ? user['user-groups'].filter((n) => n.length > 0) : [];

                    let newUser = {
                        email: user['email'],
                        organization: this.state.userDetails.organization,
                        created: createDate,
                        permissions: permissions,
                        software: software,
                        status:
                            user['login-status'] === 'force_change_password' ? 'login pending' : user['login-status'],
                        'quota-enabled': user['quota-enabled'] ? 'yes' : 'no',
                        quota: quota,
                        quotas: quotas,
                        quota_flag: user['quota-enabled'],
                        group_id: 'group-id' in user ? user['group-id'] : 'none',
                        user_groups: userGroups,
                        default_group: 'default-user-group' in user ? user['default-user-group'] : 'none',
                        priority: 'priority' in user ? user['priority'] : 'none',
                    };
                    newUsers.push(newUser);
                });
                newUsers.sort((a, b) => a.email.localeCompare(b.email));
                this.setState({
                    users: newUsers,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingUsers: false });
            });
        this.setState({ fetchingUsers: true });
    }

    /**
     *
     * Calls API to get User Groups for the org
     */
    async getUserGroups() {
        HQS_API.getUserGroups(this.state.userDetails.organization)
            .then((response) => {
                let userGroups = [];
                response.forEach((userGroup) => {
                    let newUserGroup = {
                        id: userGroup['id'],
                        name: userGroup['name'],
                        created: userGroup['create-date'],
                        'quota-enabled': userGroup['quota-enabled'] ? 'yes' : 'no',
                        quota: userGroup['quota-enabled'] ? userGroup['quota'] : 0,
                        quotas: 'quotas' in userGroup ? userGroup['quotas'] : [],
                        priority: 'priority' in userGroup ? userGroup['priority'] : 'None',
                    };
                    userGroups.push(newUserGroup);
                });
                userGroups.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({
                    userGroups: userGroups,
                });
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                this.setState({ fetchingUserGroups: false });
            });

        this.setState({ fetchingUserGroups: true });
    }

    dateRenderer(cellData) {
        const data = cellData.value;
        var createDate = '';

        if (data) {
            createDate = toISODate(data);
        }
        return <span>{createDate}</span>;
    }

    shortDateRenderer = (cell, row) => {
        const data = cell.value;
        var date = '';

        if (data) {
            date = toISODate(data, 'short');
        }
        return <span>{date}</span>;
    };

    startDateRenderer(cellData) {
        const row = cellData['rowData'];

        let createDate = toISODate(row['create_date']);
        if ('activation_date' in row && row['activation_date'] != undefined && row['activation_date'] !== '') {
            createDate = toISODate(row['activation_date']);
        }

        return <span>{createDate}</span>;
    }

    refreshStartDateRenderer(cellData) {
        const row = cellData['rowData'];

        let date = '';
        if ('refresh_start_date' in row && row['refresh_start_date'] != undefined && row['refresh_start_date'] !== '') {
            //date = toISODate(row['refresh_start_date']);

            date = getRefreshSchedule(toISODate(row['refresh_start_date']));
        }

        return <span title="The credits and fair queuing multipliers will reset to their plan defaults">{date}</span>;
    }

    statusRenderer(cellData) {
        const data = cellData.value;
        var jobStatus = data;

        if (data && data == 'pending') {
            jobStatus = 'queued';
        }
        return <span>{jobStatus}</span>;
    }

    costRenderer(cellData) {
        const data = cellData.value;
        var jobCost = 0;

        if (data) {
            jobCost = roundTwoDecimalPlaces(data);
        }

        return <span>{jobCost}</span>;
    }

    quotaRenderer(cellData) {
        const data = cellData.value;
        var quotaValue = 0;

        if (data) {
            quotaValue = roundTwoDecimalPlaces(data);
        }

        return <span>{quotaValue}</span>;
    }

    groupNamesRenderer(cellData) {
        let groupIds = cellData.value;
        let groups = 'none';

        if (groupIds.length > 0) {
            groups = '';
            groupIds.forEach((group, index) => {
                let groupCommaSepStr = index <= groupIds.length - 2 ? ', ' : '';
                groups += this.getGroupName(group) + groupCommaSepStr;
            });
        }

        return <span>{groups}</span>;
    }

    groupNameRenderer(cellData) {
        let groupId = cellData.value;
        let group = 'none';
        if (groupId !== 'none') {
            group = this.getGroupName(groupId);
        }

        return <span>{group}</span>;
    }

    getGroupName(groupId) {
        let groupName = 'none';
        if (groupId !== 'none') {
            let userGroup = this.state.userGroups.find((group) => group.id === groupId);
            if (userGroup !== undefined) {
                groupName = userGroup.name;
            }
        }

        return groupName;
    }

    ellipsisRenderer(cellData) {
        const data = cellData.value;
        return <span className="ellipsis-field">{data}</span>;
    }

    hqcRenderer = (cellData) => {
        var hqcTotal = 0;
        var accumulated = 0;
        var pending = 0;
        if ('rowData' in cellData) {
            const row = cellData['rowData'];

            if ('accumulated-hqc' in row) {
                accumulated = row['accumulated-hqc'];
            }

            if ('pending-hqc' in row) {
                pending = row['pending-hqc'];
            }
        }

        hqcTotal = roundTwoDecimalPlaces(accumulated + pending);

        return <span>{hqcTotal}</span>;
    };

    creditsRenderer = (cellData) => {
        var credits = 'NA';
        var availableCredits = 0;
        if ('rowData' in cellData) {
            const row = cellData['rowData'];

            if ('unlimited' in row && row['unlimited']) {
                credits = 'Unlimited';
            } else {
                if ('available_hqc' in row && row['available_hqc'] !== 'NA') {
                    availableCredits = row['available_hqc'];
                }
                credits = roundTwoDecimalPlaces(availableCredits);
            }
        }

        return <span>{credits}</span>;
    };

    pendingCreditsRenderer = (cellData) => {
        var credits = 'NA';
        var pendingCredits = 0;
        if ('rowData' in cellData) {
            const row = cellData['rowData'];

            if ('pending_hqc' in row && row['pending_hqc'] !== 'NA') {
                pendingCredits = row['pending_hqc'];
            }
            credits = roundTwoDecimalPlaces(pendingCredits);
        }

        return <span>{credits}</span>;
    };

    machinesRenderer(cellData) {
        const data = cellData.value;
        return <span>{Object.keys(data).join(', ')}</span>;
    }

    enableRenderer(cellData) {
        const data = cellData.value;
        return <span>{data ? 'True' : 'False'}</span>;
    }

    softwareRenderer(cellData) {
        const data = cellData.value;

        if (isLicensedPlan(data)) {
            return <span>{softwareMap[data]}</span>;
        } else {
            return <span>{data}</span>;
        }
    }

    fqMultiplierRenderer(cellData) {
        const data = cellData.value;
        var multiplier = 0;
        if (data) {
            multiplier = roundTwoDecimalPlaces(data);
        }
        return <span>{multiplier}</span>;
    }

    calculateCreditsAvailable() {
        let creditsAvailable = 0;
        if (this.state.orgDetails['available_hqc'] >= 0) {
            creditsAvailable = roundTwoDecimalPlaces(this.state.orgDetails['available_hqc']);
        }
        return creditsAvailable;
    }

    handleUserGroupRowSelect(selectedRow) {
        if (selectedRow.length === 0) {
            selectedRow = null;
        }
        this.setState({
            userGroupSelectedRow: selectedRow,
        });
    }

    handleOrgUserRowSelect(selectedRow) {
        if (selectedRow.length === 0) {
            selectedRow = null;
        }
        this.setState({
            orgUserSelectedRow: selectedRow,
        });
    }

    sortJobs(jobs, sortData) {
        if (!sortData) return;
        const { sortField, sortOrder } = sortData;
        return jobs.sort((a, b) => {
            a = a[sortField]?.toString() || '';
            b = b[sortField]?.toString() || '';
            return a.localeCompare(b) * sortOrder;
        });
    }

    toggleUserExpand(data, open) {
        let expanded = this.state.userExpandedRows;
        if (open) {
            let index = expanded.findIndex((item) => JSON.stringify(item) === JSON.stringify(data));
            expanded.splice(index, 1);
        } else {
            expanded.push(data);
        }
        this.setState({ userExpandedRows: expanded });
    }

    toggleUserGroupExpand(data, open) {
        let expanded = this.state.userGroupExpandedRows;
        if (open) {
            let index = expanded.findIndex((item) => JSON.stringify(item) === JSON.stringify(data));
            expanded.splice(index, 1);
        } else {
            expanded.push(data);
        }
        this.setState({ userGroupExpandedRows: expanded });
    }

    userGroupExpansionTemplate(data) {
        let userGroupQuotas = 'quotas' in data ? data['quotas'] : [];
        let quotaList = <div></div>;
        if (userGroupQuotas.length > 0) {
            const groupQuotas = [];

            userGroupQuotas.sort((a, b) => {
                //sort on the first machine in the plan (since we know it's fetched alphabetically)
                if ('machine' in a && 'machine' in b) {
                    if (Object.keys(a['machine'])[0].toLowerCase() < Object.keys(b['machine'])[0].toLowerCase())
                        return -1;
                    if (Object.keys(a['machine'])[0].toLowerCase() > Object.keys(b['machine'])[0].toLowerCase())
                        return 1;
                    return 0;
                }
            });
            userGroupQuotas.forEach((item) => {
                groupQuotas.push(
                    <UserGroupQuota
                        key={item['id']}
                        type={'group'}
                        orgName={this.state.userDetails.organization}
                        groupId={data['id']}
                        groupName={data['name']}
                        quotaId={item['id']}
                        machine={Object.keys(item.machine)}
                        quota={item['amount']}
                        callback={this.afterUserGroupAdd}></UserGroupQuota>,
                );
            });
            quotaList = <div className="hqs-umui-quotas-list">{groupQuotas}</div>;
        }
        return quotaList;
    }

    userExpansionTemplate(data) {
        let userQuotaRecords = 'quotas' in data ? data['quotas'] : [];
        let quotaList = <div></div>;
        if (userQuotaRecords.length > 0) {
            const userQuotas = [];
            userQuotaRecords.sort((a, b) => {
                //sort on the first machine in the plan (since we know it's fetched alphabetically)
                if ('machine' in a && 'machine' in b) {
                    if (Object.keys(a['machine'])[0].toLowerCase() < Object.keys(b['machine'])[0].toLowerCase())
                        return -1;
                    if (Object.keys(a['machine'])[0].toLowerCase() > Object.keys(b['machine'])[0].toLowerCase())
                        return 1;
                    return 0;
                }
            });
            userQuotaRecords.forEach((item) => {
                userQuotas.push(
                    <UserGroupQuota
                        key={item['id']}
                        type={'user'}
                        user={data['email']}
                        quotaId={item['id']}
                        machine={Object.keys(item.machine)}
                        quota={item['amount']}
                        callback={this.update}></UserGroupQuota>,
                );
            });
            quotaList = <div className="hqs-umui-quotas-list">{userQuotas}</div>;
        }

        return quotaList;
    }

    userGroupExpanderTemplate(data) {
        const open = this.state.userGroupExpandedRows.includes(data.rowData);
        return (
            <div onClick={() => this.toggleUserGroupExpand(data.rowData, open)}>
                <Icon root="common" name={open ? 'caret-down' : 'caret-right'} />
            </div>
        );
    }

    userExpanderTemplate(data) {
        const open = this.state.userExpandedRows.includes(data.rowData);
        return (
            <div onClick={() => this.toggleUserExpand(data.rowData, open)}>
                <Icon root="common" name={open ? 'caret-down' : 'caret-right'} />
            </div>
        );
    }

    getExamplesAccess() {
        // get temp access to fetch examples files
        HQS_API.examples()
            .then((response) => {
                AWS.config.update({
                    credentials: response,
                    region: 'us-west-2',
                });
                this.s3 = new AWS.S3({
                    apiVersion: '2006-03-01',
                    params: {
                        Bucket: APIExamplesBucket,
                        Prefix: '',
                    },
                });
            })
            .catch((error) => {
                console.error(JSON.stringify(error));
            });
    }

    /**
     * getNodeName - sets the name of the node in the tree view
     * removes "/" that comes from the S3 API
     */
    getNodeName(name, isFolder) {
        let pathStrings = name.split('/');
        return pathStrings[pathStrings.length - (isFolder ? 2 : 1)];
    }

    /**
     * getNodes - separates the response from the getAsyncNodes into files and folders
     * decorates the response for use in the Tree view
     */
    getNodes(parent) {
        // separate files from the response. All folders have a size = 0
        // the if conditions only allows files if any folders are in the response.
        let contents = [];
        forEach(parent.Contents, (val) => {
            if (val.Size > 0)
                contents.push({
                    name: this.getNodeName(val.Key, false),
                    key: val.Key,
                    level: parent.level + 1,
                    isFolder: false,
                });
        });

        // isolates the folders from the response and generates a list.
        return [
            ...map(parent.CommonPrefixes, (val) => {
                return {
                    name: this.getNodeName(val.Prefix, true),
                    prefix: val.Prefix,
                    level: parent.level + 1,
                    isFolder: true,
                };
            }),
            ...contents,
        ];
    }

    /**
     * getAsyncNodes - gets the list of objects on the S3 bucket
     * with the help of the Delimiter and Prefix, the response is sorted
     * into respective files and folders
     */
    getAsyncNodes(parent) {
        if (!parent) parent = { level: 0 };
        let prefix = parent.level === 0 ? '' : parent.prefix;

        return new Promise((resolve, reject) => {
            this.s3.listObjectsV2(
                {
                    Delimiter: '/',
                    Prefix: prefix,
                },
                (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(this.getNodes(Object.assign({}, parent, data)));
                    }
                },
            );
        });
    }

    /**
     * getObject - generates a url (url will expired after (60 seconds) that can be used to get the object data
     * stores the object's data into state.
     * TODO: handle files that can't be displayed as text
     */
    getObject(key) {
        let fileHighlightValue = fileExtensionMap[key.substring(key.lastIndexOf('.') + 1)];
        let fileFormat = fileHighlightValue !== undefined ? fileHighlightValue : 'none';
        let url = this.s3.getSignedUrl('getObject', { Key: key, Expires: 60 });
        fetch(url)
            .then((response) => response.text())
            .then((data) => {
                this.setState({
                    selectedFileData: data,
                    selectedFileFormat: fileFormat,
                    selectedFileURL: url,
                });
            });
    }

    async handleExamplesDownload() {
        //get all objects in the bucket first
        this.s3.listObjectsV2(
            {
                Bucket: APIExamplesBucket,
            },
            async (err, data) => {
                if (err) {
                    console.error(err);
                } else {
                    const zip = new JSZip();
                    // this sync loop is important as we want
                    // to wait for all files to be downloaded
                    // before creating the .zip file
                    for (const file of data.Contents) {
                        if (!file.Key.endsWith('/')) {
                            let url = this.s3.getSignedUrl('getObject', {
                                Key: file.Key,
                                Expires: 60,
                            });
                            let response = await fetch(url);
                            let fileData = await response.blob();
                            // add file to zip object
                            zip.file(file.Key, fileData);
                        }
                    }
                    // zip all files into single .zip file
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        saveAs(content, 'hqs-api-examples.zip');
                    });
                }
            },
        );
    }

    fetchReports() {
        this.setState({
            fetchingReports: true,
        });
        HQS_API.listReports()
            .then((response) => {
                response.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });
                // format report text before passing to ReportsTab
                response.forEach(function (report) {
                    // format report type
                    let report_type = report['report_type'];
                    if (report_type === 'single') {
                        report['report_type'] = 'Single Day';
                    } else if (report_type === 'multi') {
                        report['report_type'] = 'Multiple Day';
                    }

                    // format record type
                    let record_type = report['record_type'];
                    if (record_type === 'org') {
                        report['record_type'] = 'organization';
                    }

                    // format date
                    let date = report['date'];
                    report['date'] = date.split(' ')[0];
                });
                this.setState({
                    reports: response,
                    fetchingReports: false,
                });
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                this.setState({ fetchingJobs: false });
            });
    }

    render() {
        let welcomeString = `Welcome, ${this.state.userDetails.organization} Administrator`;
        let subHeaderStyle = {
            width: 'auto',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            margin: '1em 0.4em 0.5em 0',
        };
        let valuesStyle = {
            marginTop: '4px',
            fontSize: '12px',
        };

        let view = (
            <div className="hqs-org-admin">
                <div className="View-header">{welcomeString}</div>
                <Tab defaulActiveIndex={0}>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="user-group" size="small">
                                &nbsp;Users
                            </Icon>
                        }>
                        <div className="hqs-org-view">
                            <div className="hqs-umui-card">
                                <div className="hqs-umui-card-header" style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: '2px' }}>USERS&nbsp;&nbsp;</div>
                                    <div
                                        id="icon-refresh-users"
                                        onClick={this.getUsersOfOrganization}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.8rem',
                                        }}>
                                        <Icon
                                            root="common"
                                            name="refresh"
                                            size="small"
                                            // loading={true}
                                        />
                                    </div>
                                </div>
                                <DataTable
                                    data={this.state.users}
                                    selection={this.state.orgUserSelectedRow}
                                    selectionMode="single"
                                    onSelectAll={(e) => this.setState({ selectedAll: e })}
                                    onSelectionChange={(e) => this.handleOrgUserRowSelect(e)}
                                    search={true}
                                    scrollable={true}
                                    resizableColumns={true}
                                    scrollHeight="600px"
                                    loading={this.state.fetchingUsers}
                                    expandedRows={this.state.userExpandedRows}
                                    rowExpansionTemplate={this.userExpansionTemplate}>
                                    <DataTable.HeaderBar>
                                        <OrgUserActionSelect
                                            selectedRow={this.state.orgUserSelectedRow}
                                            org_name={this.state.userDetails.organization}
                                            org_groups={this.state.userGroups}
                                            org_plans={this.state.orgPlans}
                                            org_licenses={this.state.orgLicenses}
                                            user_priority={this.state.userPriority}
                                            callback={this.update}
                                        />
                                        <CreateUserForm
                                            orgs={[this.state.userDetails.organization]}
                                            mode={this.state.mode}
                                            orgLicenses={this.state.orgLicenses}
                                            isHqsAdmin={false}
                                            callback={this.update}
                                        />
                                    </DataTable.HeaderBar>
                                    <DataTable.Column
                                        initialWidth="3rem"
                                        field="plane"
                                        header="Show"
                                        renderer={this.userExpanderTemplate}
                                    />
                                    <DataTable.Column
                                        field="email"
                                        header="USER LOGIN"
                                        sortable={true}
                                        initialWidth="200px"
                                    />
                                    <DataTable.Column
                                        field="created"
                                        header="DATE ADDED"
                                        sortable={true}
                                        renderer={this.dateRenderer}
                                    />
                                    <DataTable.Column field="status" header="STATUS" sortable={true} />
                                    <DataTable.Column field="quota-enabled" header="Quota Enabled" sortable={true} />
                                    <DataTable.Column
                                        field="user_groups"
                                        header="GROUPS"
                                        renderer={this.groupNamesRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="default_group"
                                        header="DEFAULT GROUP"
                                        renderer={this.groupNameRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column field="priority" header="PRIORITY" sortable={true} />
                                    <DataTable.Column
                                        field="permissions"
                                        header="PERMISSIONS"
                                        sortable={true}
                                        initialWidth="400px"
                                    />
                                    <DataTable.Column
                                        field="software"
                                        header="SOFTWARE"
                                        sortable={true}
                                        initialWidth="300px"
                                    />
                                </DataTable>
                                <ToastContainer
                                    hideProgressBar={true}
                                    closeOnClick={false}
                                    closeButton={false}
                                    newestOnTop={true}
                                    position="bottom-right"
                                    toastClassName="toast-notification-wrap"
                                />
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="grid" size="small">
                                &nbsp;User Groups
                            </Icon>
                        }>
                        <div className="hqs-org-view">
                            <div className="hqs-umui-card">
                                <div className="hqs-umui-card-header" style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: '2px' }}>USER GROUPS&nbsp;&nbsp;</div>
                                    <div
                                        id="icon-refresh-user-groups"
                                        onClick={this.getUserGroups}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.8rem',
                                        }}>
                                        <Icon root="common" name="refresh" size="small" />
                                    </div>
                                </div>
                                <DataTable
                                    data={this.state.userGroups}
                                    search={true}
                                    selection={this.state.userGroupSelectedRow}
                                    selectionMode="single"
                                    onSelectAll={(e) => this.setState({ selectedAll: e })}
                                    onSelectionChange={(e) => this.handleUserGroupRowSelect(e)}
                                    scrollable={true}
                                    resizableColumns={true}
                                    scrollHeight="550px"
                                    loading={this.state.fetchingUserGroups}
                                    expandedRows={this.state.userGroupExpandedRows}
                                    rowExpansionTemplate={this.userGroupExpansionTemplate}>
                                    <DataTable.HeaderBar>
                                        <GenerateUserGroupUsageReportForm
                                            key={this.state.fetchingUserGroups}
                                            userGroups={this.state.userGroups}
                                            org_name={this.state.userDetails.organization}
                                            disabled={this.state.userGroups.length == 0}
                                            allGroups={true}
                                        />
                                        <UserGroupActionSelect
                                            key={this.state.userGroupSelectedRow}
                                            selectedRow={this.state.userGroupSelectedRow}
                                            org_name={this.state.userDetails.organization}
                                            org_plans={this.state.orgPlans}
                                            callback={this.afterUserGroupAdd}
                                        />
                                        <AddUserGroupForm
                                            org={this.state.userDetails.organization}
                                            callback={this.afterUserGroupAdd}
                                        />
                                    </DataTable.HeaderBar>
                                    <DataTable.Column
                                        initialWidth="3rem"
                                        field="plane"
                                        header="Show"
                                        renderer={this.userGroupExpanderTemplate}
                                    />

                                    <DataTable.Column field="name" header="GROUP NAME" sortable={true} />
                                    <DataTable.Column field="priority" header="PRIORITY" sortable={true} />
                                    <DataTable.Column
                                        field="created"
                                        header="DATE ADDED"
                                        sortable={true}
                                        renderer={this.dateRenderer}
                                    />
                                    <DataTable.Column field="quota-enabled" header="Quota Enabled" sortable={true} />
                                </DataTable>
                                <ToastContainer
                                    hideProgressBar={true}
                                    closeOnClick={false}
                                    closeButton={false}
                                    newestOnTop={true}
                                    position="bottom-right"
                                    toastClassName="toast-notification-wrap"
                                />
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="calendar" size="small">
                                &nbsp;Calendar
                            </Icon>
                        }>
                        <CalendarTab
                            mode={this.state.mode}
                            allMachines={this.state.allMachines}
                            orgNames={this.state.orgNames}
                            organization={this.state.userDetails.organization}></CalendarTab>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="communication" size="small">
                                &nbsp;Jobs
                            </Icon>
                        }>
                        <JobsTab
                            mode={this.state.mode}
                            jobs={this.state.jobs}
                            page={this.state.page}
                            search={this.state.search}
                            filters={this.state.filters}
                            organizations={this.state.orgList}
                            onChange={this.getOrgJobs}
                            onSort={(sortData) => {
                                const newSort = this.sortJobs(this.state.jobs, sortData);
                                this.setState({
                                    jobs: newSort,
                                    jobsSortInfo: sortData,
                                });
                            }}
                            {...this.state.jobsSortInfo}
                            fetchingJobs={this.state.fetchingJobs}
                            fetchJobsReport={this.fetchJobsReport}
                        />
                        <a style={{ display: 'none' }} href="" ref={this.blobRef} download="" />
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="folder" size="small">
                                &nbsp;Reports
                            </Icon>
                        }>
                        <ReportsTab
                            reports={this.state.reports}
                            orgs={this.state.orgList}
                            fetchingReports={this.state.fetchingReports}
                            mode="org"
                            callback={this.fetchReports}
                        />
                    </Tab.Pane>

                    <Tab.Pane
                        title={
                            <Icon root="common" name="image-responsive" size="small">
                                &nbsp;Batch Requests
                            </Icon>
                        }>
                        <BatchRequests mode={this.state.mode} org={this.state.userDetails.organization}></BatchRequests>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="display-adjust" size="small">
                                &nbsp;Machines
                            </Icon>
                        }>
                        <div className="hqs-org-view">
                            <div className="hqs-umui-card">
                                <div className="hqs-umui-card-header" style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: '2px' }}>MACHINES&nbsp;&nbsp;</div>
                                    <div
                                        id="icon-refresh-org-details"
                                        onClick={this.getOrgDetails}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '0.8rem',
                                        }}>
                                        <Icon root="common" name="refresh" size="small" loading={true} />
                                    </div>
                                </div>
                                <DataTable data={this.state.orgPlans} search={true} scrollHeight="600px">
                                    <DataTable.Column
                                        field="machine_name"
                                        header="MACHINE"
                                        initialWidth="120px"
                                        renderer={this.machinesRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="enabled"
                                        header="ENABLED"
                                        initialWidth="70px"
                                        renderer={this.enableRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="activation_date"
                                        header="ACTIVATION DATE"
                                        renderer={this.startDateRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="refresh_start_date"
                                        header="REFRESH SCHEDULE"
                                        renderer={this.refreshStartDateRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="deactivation_date"
                                        header="DEACTIVATION DATE"
                                        renderer={this.dateRenderer}
                                        initialWidth="100px"
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="available_hqc"
                                        header="AVAILABLE CREDITS"
                                        initialWidth="90px"
                                        renderer={this.creditsRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="pending_hqc"
                                        header="PENDING CREDITS"
                                        initialWidth="90px"
                                        renderer={this.pendingCreditsRenderer}
                                        sortable={true}
                                    />
                                    <DataTable.Column
                                        field="software"
                                        header="SOFTWARE"
                                        initialWidth="75px"
                                        renderer={this.softwareRenderer}
                                        sortable={true}
                                    />
                                </DataTable>
                                <ToastContainer
                                    hideProgressBar={true}
                                    closeOnClick={false}
                                    closeButton={false}
                                    newestOnTop={true}
                                    position="bottom-right"
                                    toastClassName="toast-notification-wrap"
                                />
                            </div>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane
                        title={
                            <Icon root="common" name="folder" size="small">
                                &nbsp;Examples
                            </Icon>
                        }>
                        <div className="hqs-user-view">
                            <div className="hqs-user-card medium-card hqs-custom-card">
                                <div
                                    style={{
                                        overflow: 'auto',
                                        padding: '0px 7px 0px 7px',
                                    }}>
                                    <TreeView
                                        treeLoading={this.state.treeLoading}
                                        getAsyncNodes={this.getAsyncNodes}
                                        getObject={this.getObject}
                                    />
                                </div>
                                <div>
                                    {' '}
                                    <Button
                                        type="primary"
                                        size="small"
                                        content="&nbsp;Download"
                                        icon="file-download"
                                        onClick={this.handleExamplesDownload}
                                    />
                                </div>
                            </div>

                            <div className="hqs-user-card editor-card">
                                <div
                                    style={{
                                        overflow: 'auto',
                                        width: '100%',
                                    }}>
                                    <CodeViewer
                                        fileFormat={this.state.selectedFileFormat}
                                        fileData={this.state.selectedFileData}
                                        fileURL={this.state.selectedFileURL}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
                </Tab>
            </div>
        );

        return view;
    }
}

export default OrgAdmin;
