/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { Component, useState } from 'react';
import { Button } from '@scuf/common';
import './SignUp.css';
const logo = require('./QuantinuumLogo.svg');


const SignUpComplete = (props) => {
    function backToSignIn() {
        //update window to return to login page
        window.location.replace("/login");
      
    }

    return (
        <div className="custom-sign-up">
            <div className="sign-up-complete-form">
                <div className="form-header">
                    <img className="logo" src={logo}></img>
                </div>
                <div className="form-completed">
                    <h4>All set!</h4>
                    <p>Please return to the login page to start using the system.</p>
                    <Button type="primary" onClick={() => backToSignIn()} content="Return to Login" />
                </div>
            </div>
        </div>
        
    )
};

export default SignUpComplete;