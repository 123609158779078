/*****************************************************************************                                          
 *                                                                                                 
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.                                                         
 *                                                                                                  
 *****************************************************************************/

import React, { useState } from 'react';
import { Button, Input, Divider, Icon } from '@scuf/common';
import * as HQS_API from '../utils/api';
import { passwordPolicy, isValidEmail, isExempt } from '../utils/helpers';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import ToastNotification from '../Notifications/ToastNotification';
import { MsalProvider } from "@azure/msal-react";
import {SignInAzure} from '../Forms/SignInAzure';
import { useMsal } from "@azure/msal-react";

import './SignUp.css';
import SignUpAgreement from './SignUpAgreement';
const logo = require('./QuantinuumLogo.svg');

const SignUp = (props) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inviteId, setInviteId] = useState(props.inviteId)
    const [passwordAttributes, setPasswordAttributes] = useState('');
    const [passwordMatch, setPasswordMatch] = useState('');
    const [formHeight, setFormHeight] = useState('830px');
    const [homePage, setHomePage] = useState(props.homePage);
    const [emailRequired, setEmailRequired] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [agreements, setAgreements] = useState(props.agreements)
    const { instance } = useMsal();

    
    const steps = {
        start: 1,
        agreement: 2,
        end: 3

    }

    const [step, setStep] = useState(steps.start);

    function showContent() {
        //dynamically show the main content
    
        switch (step) {
          case steps.agreement:
            return showAgreement();
          case steps.start:
          default:
            return showStart();
        }
      }


    function handleEmail(emailValue) {
        setEmail(emailValue.trim().toLowerCase());

        let isEmail = isValidEmail(emailValue.trim().toLowerCase())
        if (!isEmail){
            setValidEmail(false);
            setEmailError("Please enter a valid email address!")
        } else {
            setValidEmail(true);
            setEmailError('');
        }

        if (emailValue.length > 0 ) {
            setEmailRequired(true);
        } else {
            setEmailRequired(false);
            setEmailError('');
            setValidEmail(false);
        }
        
    }

     //define the rules we need to communicate to the user
    function buildPasswordPolicy() {

        let rules = []

        let lengthRule = {
            id: 1,
            rule: "Be at least " + passwordPolicy.minLength + " characters",
            passed: passwordAttributes.length >= passwordPolicy.minLength
        }
        let upperRule = {
            id: 2,
            rule: "Contain at least 1 uppercase letter",
            passed: passwordAttributes.anyUpper && passwordPolicy.upperCase
        }
        let lowerRule = {
            id: 3,
            rule: "Contain at least 1 lowercase letter",
            passed: passwordAttributes.anyLower && passwordPolicy.lowerCase
        }
        let numberRule = {
            id: 4,
            rule: "Contain at least 1 number",
            passed: passwordAttributes.anyNumber && passwordPolicy.number
        }

        let matchRule = {
            id: 6,
            rule: "Passwords match",
            passed: passwordMatch
        }

        rules.push(lengthRule);
        rules.push(upperRule);
        rules.push(lowerRule);
        rules.push(numberRule);
        rules.push(matchRule);

        return rules
    }

    //tests if a string contains a number
    function hasNumber(value) {
        return /\d/.test(value);
    }

    //tests if a string contains a lowercase letter
    function hasLowerCase(value) {
        return (/[a-z]/.test(value));
    }

    //tests if a string contains a uppercase letter
    function hasUpperCase(value) {
        return (/[A-Z]/.test(value));
    }

     //determines which validation color to return 
    function getValidationColor(value) {
        if (value) {
            return "green"
        } else {
            return "red"
        }
    }

    //validate whether or not the password meets the password policy and any other requirements
    function validatePassword(password) {

        let attributes = {
            length: password.length,
            anyUpper: hasUpperCase(password),
            anyLower: hasLowerCase(password),
            anyNumber: hasNumber(password),
        }

        setPasswordAttributes(attributes);
    }

     //handle user input into the new password text field
    function handlePassword(value) {
        setPassword(value);
        validatePassword(value);
        //check if the new confirm password still matches the new password
        setPasswordMatch(confirmPassword == value)
    }

    //handle user input into the confirm password text field
    function handleConfirmPassword(value) {
        setConfirmPassword(value);
        //check if the new password still matches the confirm password
        setPasswordMatch(password == value)
    }

    function genPolicyValidations(){
        let policies = buildPasswordPolicy()
        const listItems = policies.map((policy) =>
            <li key={policy.id}>
                {policy.rule}&nbsp;&nbsp;
                {passwordAttributes.length > 0 ? (
                    <span>
                        <Icon
                            root="common"
                            name="badge-check"
                            size="small"
                            color={getValidationColor(policy.passed)}
                        />
                    </span>) : ''
                }
            </li>
        );
        return listItems
    }

    //evalute if the password is valid
    function isPasswordValid() {

        //met length requirement
        let meetsLength = (passwordAttributes.length >= passwordPolicy.minLength)

        //met uppercase requirement
        let meetsUpper = (passwordAttributes.anyUpper && passwordPolicy.upperCase)

        //met lowercase requirement
        let meetsLower = (passwordAttributes.anyLower && passwordPolicy.lowerCase)

        //met number requirement
        let meetsNumber = (passwordAttributes.anyNumber && passwordPolicy.number)

        return (meetsLength && meetsUpper && meetsLower && meetsNumber && passwordMatch)
    }

    //generate the password policy section so the user knows if the new password has met the requirements
    function genPasswordPolicy() {
        
        return (
            <div>
                <p>Password must meet these requirements:</p>
                <ul>
                    {genPolicyValidations()}
                </ul>
            </div>
        );
    }

     // function to validate all form fields
    function validFormValues() {
        return (validEmail && isPasswordValid());
    }

    function handleRegistration() {
        const queryParams = new URLSearchParams(window.location.search);
        const invitationId = queryParams.get('id');
        console.log("invitation id:" + invitationId)
        setInviteId(invitationId)
        const body = {
            "invite-id": invitationId,
            "password": password,
            "email": email
        };
        setFormLoading(true);
        HQS_API.userRegistration(body)
            .then((response) => {
                console.log("Registration response: " + response)

                let authChallenges = ""

                if ('auth-challenge' in response){
                    authChallenges=response['auth-challenge'].join(",")
                }
                setAgreements(authChallenges)

                // check if the user is exempt from terms and conditions
                if (isExempt(authChallenges)){
                    //route to the registration complete page
                    window.location.replace("/registration-completed");
                }else{
                    //update page to present the challenge for the user
                    setStep(steps.agreement)
                }

                setFormLoading(false);

            })
            .catch((error) => {
                setFormLoading(false);
                if (error !== undefined) {
                    error = error.response.data.error
                    const title = "Unable to complete registration!";
                    let details = error.text
                    toast(
                        <ToastNotification
                            closeToast={false}
                            title={title}
                            details={details}
                            severity="critical"
                        />
                    );
                }
            });
    }

    function showAgreement(){
        return  <SignUpAgreement inviteId={inviteId} email={email} agreements={agreements} homePage={homePage} />

    }


    function showStart(){

    if (formLoading) {
        return (
            <div className="custom-sign-up">
                <div className="sign-up-form" style={{height: 300}}>
                    <p className="form-completed-p">
                        <Icon name="refresh" size="xlarge" loading={true} color="#1274B7"/>
                    </p>
                    
                </div>
            </div>
        )
    }   
    else if (inviteId === null) {
        return (
            <div className="custom-sign-up">
                <div className="sign-up-form" style={{height: formHeight}}>
                    <div className="form-header">
                        <img className="logo" src={logo}></img>
                    </div>
                    <p >Invalid Invitation!</p>
                    <p>Please contact Quantinuum at qcsupport@quantinuum.com and your organization administrator.</p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="custom-sign-up">
                <div className="sign-up-form" style={{height: formHeight}}>
                    <div className="form-header">
                        <img className="logo" src={logo}></img>
                    </div>
                    <div className="credentials">
                        <p>Welcome, to complete the registration process please either create an account with us or select a third party authentication provider. </p>
                        <div className="username">
                            <Input 
                                className="input-form"
                                indicator={emailRequired ? "required" : ""}
                                fluid="true" 
                                id="username" 
                                key="username" 
                                name="username" 
                                type="text" 
                                autoFocus 
                                pattern="[^\s]+"
                                label="Email" 
                                value={email}
                                error={emailError}
                                onChange={handleEmail}
                            />
                        </div>
                        <p></p>
                        <div>
                            <div className="password">
                                {genPasswordPolicy()}
                                <Input 
                                    className="input-form"
                                    indicator="required" 
                                    type="password" 
                                    fluid="true" 
                                    label="Password" 
                                    value={password} 
                                    onChange={handlePassword}
                                />
                            </div>
                            <div className="password">
                                <Input 
                                    className="input-form"
                                    indicator="required"
                                    type="password" 
                                    fluid="true" 
                                    label="Confirm Password" 
                                    value={confirmPassword} 
                                    onChange={handleConfirmPassword}
                                />
                            </div>
                        </div>
                        <div className="submit-creds">
                        <Button 
                            type="primary" 
                            content="Submit" 
                            onClick={() => handleRegistration('cognito')} 
                            disabled={!validFormValues()}
                        />
                        </div>
                        <div>
                            <Divider direction='horizontal'> or </Divider>
                            
                            <MsalProvider instance={instance}>
                                <SignInAzure action="signup" />
                            </MsalProvider>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    hideProgressBar={true}
                    closeOnClick={false}
                    closeButton={false}
                    newestOnTop={true}
                    position="bottom-right"
                    toastClassName="toast-notification-wrap"
                />
            </div>
            
        )
    }
    }


    return (
        
           showContent()
            
    )
    
     
};

export default SignUp;