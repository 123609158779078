/*****************************************************************************
 *
 * QUANTINUUM LLC CONFIDENTIAL & PROPRIETARY.
 * This work and all information and expression are the property of
 * Quantinuum LLC, are Quantinuum LLC Confidential & Proprietary,
 * contain trade secrets and may not, in whole or in part, be licensed,
 * used, duplicated, disclosed, or reproduced for any purpose without prior
 * written permission of Quantinuum LLC.
 *
 * In the event of publication, the following notice shall apply:
 * (c) 2020-2022 Quantinuum LLC. All Rights Reserved.
 *
 *****************************************************************************/

import React from 'react';
import { useState } from 'react';
import * as HQS_API from '../utils/api';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import ToastNotification from '../Notifications/ToastNotification';
import { Button, Icon, InputLabel, Modal, Select, Input, Checkbox } from '@scuf/common';
import {
    isNullOrEmpty,
    getFriendlySoftwareDescription,
    isValidEmail,
    toISODate,
    arrayRange,
    softwareMap
} from '../utils/helpers';
import moment from 'moment';

const CreateUserForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [email, setEmail] = useState(undefined);
    const [emailError, setEmailError] = useState('');
    const [org, setOrg] = useState(undefined);
    const [orgError, setOrgError] = useState('');
    const [userGroups, setUserGroups] = useState(null);
    const [defaultGroup, setDefaultGroup] = useState(null);
    const [isHqsAdmin, setIsHqsAdmin] = useState(false);
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);
    const [isOperator, setIsOperator] = useState(false);
    const [isProduct, setIsProduct] = useState(false);
    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const [userTypeError, setUserTypeError] = useState('');
    const [defaultGroupError, setDefaultGroupError] = useState('');
    const [userSoftware, setUserSoftware] = useState(new Set());
    const [priority, setPriority] = useState(5);

    function handleEmail(emailValue) {
        setEmail(emailValue.toLowerCase());

        let isEmail = isValidEmail(emailValue.trim().toLowerCase());
        if (!isEmail) {
            setEmailError('Please enter a valid email address!');
        } else {
            setEmailError('');
        }
    }

    function handleClose() {
        setEmailError('');
        setOrgError('');
        setDefaultGroupError('');
        setUserTypeError('');
        setIsOrgAdmin(false);
        setIsHqsAdmin(false);
        setIsOperator(false);
        setIsProduct(false);
        setEmail(undefined);
        setOrg(undefined);
        setShow(false);
        setDefaultGroup(null);
        setUserGroups(null);
        setUserSoftware(new Set());
        setPriority(5);
    }

    function handleSoftwareChecked(checked, item) {
        let pending = new Set([...userSoftware]);
        if (checked) {
            if (!pending.has(item)) {
                //add to the set of user software
                pending.add(item);
            }
        } else {
            if (pending.has(item)) {
                //remove from the set of user software
                pending.delete(item);
            }
        }

        setUserSoftware(pending);
    }

    function isSoftwareChecked(software) {
        let checked = false;

        if (userSoftware.has(software)) {
            checked = true;
        }

        return checked;
    }

    function handleOrgChange(orgValue) {
        setOrg(orgValue);
        setOrgError('');
        // get org groups for selected org
        setUserGroupOptions([]);
        setUserGroups(null);
        HQS_API.getUserGroups(orgValue)
            .then((response) => {
                let groups = [];
                response.forEach((group) => {
                    let formattedGroup = {
                        text: group['name'],
                        value: group['id'],
                    };
                    groups.push(formattedGroup);
                });
                setUserGroupOptions(groups);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function handleUserGroupsChange(groups) {
        setUserGroups(groups);

        // clear default group if groups is empty
        if (groups.length === 0) {
            setDefaultGroup(null);
            setDefaultGroupError('');
        } else {
            if (groups.includes(defaultGroup)) {
                setDefaultGroupError('');
            }
        }
    }

    function handleDefaultGroupChange(group) {
        setDefaultGroup(group);
        //check if default group is already added to user group list
        if (!userGroups.includes(group)) {
            setUserGroups([...userGroups, group]);
            setDefaultGroupError('');
        } else {
            setDefaultGroupError('');
        }
    }

    function handleIsOrgAdmin(checked) {
        setIsOrgAdmin(checked);
        setUserTypeError('');
    }

    function handleIsHqsAdmin(checked) {
        setIsHqsAdmin(checked);
        setUserTypeError('');
    }

    function handleIsOperator(checked) {
        setIsOperator(checked);
        setUserTypeError('');
    }

    function handleIsProduct(checked) {
        setIsProduct(checked);
        setUserTypeError('');
    }

    function genOrgOptions(orgs) {
        const formattedOrgs = [];
        orgs.forEach((org) => {
            let formattedOrg = { value: org, text: org };
            formattedOrgs.push(formattedOrg);
        });
        formattedOrgs.sort((a, b) => a.value.localeCompare(b.value));

        return (
            <Select
                id="selectOrg"
                options={formattedOrgs}
                placeholder="Select Org"
                label="Organization"
                onChange={(text) => handleOrgChange(text)}
                reserveSpace={true}
                fluid={true}
                search={true}
                error={orgError}
                indicator="required"
                value={org}
            />
        );
    }

    function genPriorityOptions() {
        const formattedPriorities = [];
        const priorityArray = arrayRange(1, 10, 1);
        priorityArray.forEach((priority) => {
            let formattedPriority = { value: priority, text: priority };
            formattedPriorities.push(formattedPriority);
        });

        return (
            <Select
                id="selectPriority"
                options={formattedPriorities}
                placeholder="Select Priority"
                onChange={(text) => setPriority(text)}
                reserveSpace={true}
                fluid={true}
                search={true}
                indicator="required"
                value={priority}
            />
        );
    }

    function genUserGroupOptions() {
        return (
            <Select
                id="selectUserGroup"
                options={userGroupOptions}
                placeholder="Select Group(s)"
                label="User Group(s)"
                indicator="optional"
                onChange={(group) => handleUserGroupsChange(group)}
                reserveSpace={true}
                fluid={true}
                search={true}
                multiple={true}
                value={userGroups}
            />
        );
    }

    function genDefaultGroupOptions() {
        return (
            <Select
                id="defaultUserGroup"
                options={userGroupOptions}
                placeholder="Select Default Group"
                label="Default Group"
                indicator="required"
                onChange={(group) => handleDefaultGroupChange(group)}
                reserveSpace={true}
                fluid={true}
                search={true}
                value={defaultGroup}
                error={defaultGroupError}
            />
        );
    }

    function isSoftwareDisabled(license, software) {
        let disabled = false;
        let available = license['max'] - license['users'];

        //if no licenses are available then we need to disable this option when creating the user
        if (available <= 0) {
            disabled = true;
        }

        let expired = isSoftwareExpired(license, software);

        if (expired) {
            disabled = true;
        }

        return disabled;
    }

    function isSoftwareExpired(license, software) {
        let expired = false;
        if (software && license) {
            //check if it's expired
            let expires = license['expiration'];

            //check if expiration date has passed
            expired = moment() >= moment(expires);
        }
        return expired;
    }

    function shortDateRenderer(date) {
        let formatDate = '';
        if (date) {
            formatDate = toISODate(date, 'short');
        }
        return <span>{formatDate}</span>;
    }

    function genOrgLicenseOptions(orgs) {
        let softwareList = <div></div>;
        const software = [];

        if (org !== undefined) {
            let found = orgs.filter((s) => s.name === org);

            if (found.length > 0) {
                let licenses = found[0]['licenses'];

                if (licenses !== undefined && licenses.length > 0){

                    licenses.forEach((item, index) => {
        
                        let software_name = item['software']
                        software.push(
                    <div title={getFriendlySoftwareDescription(software_name)}>
                        <Form.Group controlId="software">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '100%' }}>
                                    <Checkbox
                                        label={softwareMap[software_name]}
                                        checked={isSoftwareChecked(software_name)}
                                        onChange={(checked) => handleSoftwareChecked(checked, software_name)}
                                        disabled={isSoftwareDisabled(item, software_name)}
                                    />
                                </div>
                                <div
                                    style={{ width: '100%' }}
                                    title="The total number software licenses used out of what's available">
                                    <p>
                                        <b>Licenses Used:</b>{' '}
                                        {item['users'] + '/' + item['max']}
                                    </p>
                                </div>
                                {item['customer'] != 'trial' ? (
                                    <div
                                        style={{ width: '100%' }}
                                        title="The date the software license will expire">
                                        <p>
                                            <b>Expires: </b> {shortDateRenderer(item['expiration'])}
                                        </p>
                                    </div>
                                ) : (
                                    <div style={{ width: '100%' }} title="The duration of the software license">
                                        <p>
                                            <b>Duration: </b> {item['duration']} day(s)
                                        </p>
                                    </div>
                                )}
                            </div>
                        </Form.Group>
                    </div>,
                    );
                });
            }
        }
    }
        

        if (software.length == 0) {
            software.push(<p>None</p>);
        }

        softwareList = <div>{software}</div>;

        return softwareList;
    }

    function validateForm() {
        let validData = true;

        /** check email */
        if (email === undefined || !isValidEmail(email)) {
            validData = false;
            setEmailError('Please enter a valid email address!');
        }

        /** check org */
        if (org === undefined) {
            validData = false;
            setOrgError('Please select an organization!');
        }

        /** make sure only operator, product, or hqs admin is selected. */
        if ((isHqsAdmin && isOperator) || (isHqsAdmin && isProduct) || (isOperator && isProduct)) {
            validData = false;
            setUserTypeError('User can only be one of HQS Admin, Operator, or Product.');
        }

        /** make sure default group is populated if groups has values */
        if (userGroups !== null && userGroups.length > 0 && defaultGroup === null) {
            validData = false;
            setDefaultGroupError('Please select a default group!');
        }

        return validData;
    }

    function handleCreate() {
        if (validateForm()) {
            const body = {
                email: email,
                org_name: org,
                hqs_admin: isHqsAdmin,
                operator: isOperator,
                org_admin: isOrgAdmin,
                product: isProduct,
                priority: priority,
            };

            if (userGroups !== null && userGroups.length > 0) {
                body['user-groups'] = userGroups;
            }

            if (defaultGroup !== null) {
                body['default-user-group'] = defaultGroup;
            }

            if (userSoftware.size > 0) {
                body['software'] = [...userSoftware];
            }

            HQS_API.createUser(body)
                .then(() => {
                    const title = 'User Added';
                    const details =
                        'The system has successfully added a new user and sent them confirmation and access details';
                    toast(
                        <ToastNotification severity={'success'} closeToast={false} title={title} details={details} />,
                    );
                    if (props.callback) {
                        props.callback();
                    }
                })
                .catch((error) => {
                    const title = 'Error!';
                    var details = 'Unknown Error!';
                    if (error.response !== undefined) {
                        details = error.response.data.error.text;
                    }
                    toast(<ToastNotification closeToast={false} title={title} details={details} severity="critical" />);
                });
            handleClose();
        }
    }

    return (
        <div>
            <Button id="btn-add-new-user" type="primary" size="small" onClick={handleShow}>
                <Icon root="common" name="slidercontrols-plus" size="small"></Icon>
                &nbsp;{props.children || 'Add New User'}
            </Button>

            <Modal
                id="modal-create-user"
                open={show}
                onClose={handleClose}
                size="small"
                style={{ padding: '50px', display: 'flex', flexDirection: 'column' }}>
                <Modal.Header>
                    <div className="hqs-umui-modal-header ">
                        <Icon root="common" name="user-add" size="medium"></Icon>
                        &nbsp;&nbsp;Add New User
                    </div>
                </Modal.Header>
                <Modal.Content scrolling={false}>
                    <p>A new new user will be created and an email sent to the address with access instructions.</p>
                    <Form.Group controlId="formBasicEmail">
                        <Input
                            id="input-email"
                            indicator="required"
                            label="Email"
                            type="email"
                            placeholder="person@email.com"
                            name="email"
                            helperText="An email will be sent to the address with access information"
                            onChange={handleEmail}
                            value={email}
                            fluid={true}
                            error={emailError}
                        />
                    </Form.Group>
                    <Form.Group controlId="org">
                        <div style={{ 'max-height': '60px' }}>{genOrgOptions(props.orgs || [])}</div>
                    </Form.Group>
                    <Form.Group controlId="userGroup">
                        <div style={{ 'max-height': '60px', display: org !== undefined ? '' : 'none' }}>
                            {genUserGroupOptions(props.orgs || [])}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="defaultUserGroup">
                        <div style={{ 'max-height': '60px', display: userGroups !== null ? '' : 'none' }}>
                            {genDefaultGroupOptions(props.orgs || [])}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="isOrgAdmin">
                        <InputLabel label="User Type" indicator="optional" />
                        <Checkbox
                            id="checkboxOrgAdmin"
                            label="Organization Administrator"
                            checked={isOrgAdmin}
                            onChange={(checked) => handleIsOrgAdmin(checked)}
                        />
                    </Form.Group>
                    {props.showOperator && (
                        <Form.Group controlId="isOperator">
                            <Checkbox
                                id="checkboxOperator"
                                label="Operator"
                                checked={isOperator}
                                onChange={(checked) => handleIsOperator(checked)}
                            />
                        </Form.Group>
                    )}
                    {props.showProduct && (
                        <Form.Group controlId="isProduct">
                            <Checkbox
                                id="checkboxProduct"
                                label="Product"
                                checked={isProduct}
                                onChange={(checked) => handleIsProduct(checked)}
                            />
                        </Form.Group>
                    )}
                    {props.showHqsAdmin && (
                        <Form.Group controlId="isHqsAdmin">
                            <Checkbox
                                id="checkboxAdmin"
                                label="HQS Administrator"
                                checked={isHqsAdmin}
                                onChange={(checked) => handleIsHqsAdmin(checked)}
                            />
                        </Form.Group>
                    )}
                    <div className="below-text">
                        <span className="ui error-message">{userTypeError}</span>
                    </div>
                    <div>
                        <InputLabel label="Job Execution Priority" indicator="required" />
                        <p>
                            Select a priority value between 1 and 10.
                            <br /> 1 is the "highest" priority and 10 is the "lowest" priority
                        </p>
                        <Form.Group controlId="priority">{genPriorityOptions()}</Form.Group>
                    </div>
                    <div style={{ display: props.orgs !== undefined ? '' : 'none' }}>
                        <InputLabel label="Software" indicator="optional" />
                        <Form.Group controlId="software">{genOrgLicenseOptions(props.orgLicenses || [])}</Form.Group>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button id="btn-create-user" type="primary" onClick={handleCreate}>
                        Add
                    </Button>
                    <Button type="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateUserForm;
