// *****************************************************************************

// 2024 @ Honeywell International.
// This software and all information and expression are the property of
// Honeywell International, Inc., are Honeywell Confidential & Proprietary,
// contain trade secrets and may not, in whole or in part, be licensed,
// used, duplicated, disclosed, or reproduced for any purpose without prior
// written permission of Honeywell International Inc.
// All Rights Reserved.

// *****************************************************************************

import React from 'react';
import { useState, useEffect } from 'react';
import * as HQS_API from '../utils/api';
import {
    Button,
    Input,
    InputLabel,
    Icon,
    Notification,
    Modal,
    Select,
    DatePicker,
    Checkbox,
    Divider,
    Tab,
    Badge,
} from '@scuf/common';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import {
    isEmulator,
    isNullOrEmpty,
    customerTypes,
    getEula,
    getSoftwareCustomerChallenge,
    getSoftwareDurationByCustomer,
    softwareMap,
    toISODate,
    toLocalTime,
    maxTagValueLength,
    maxTags,
    isLicensedPlan,
} from '../utils/helpers';
import {
    refreshEndModes,
    licenseEndModes,
    defaults,
    getDate,
    hasDate,
    getTimeRemainingInDays,
    getLicenseEndMode,
    getNumberOfRefreshes,
    getLastRefreshDate,
    getRefreshSchedule,
    getRefreshStopModeSummary,
    renderActivationDate,
    renderDeactivationDate,
    renderDate,
    getTimeZoneSummary,
    getNumberOfRefreshesSummary,
    softwareProductLinks,
} from '../utils/anyPlan';
import moment from 'moment-timezone';
import './ManageAnyPlan.css';

const ToastNotification = ({ closeToast, title, details, severity }) => (
    <Notification
        className="toast-notification"
        severity={severity}
        onCloseClick={closeToast}
        hasIcon={true}
        title={title}>
        {details}
    </Notification>
);

const inquantoLogo = require('../App/InQuantoLogo.svg');

const ManageAnyPlanForm = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const [org, setOrg] = useState(props.org);
    const [orgType, setOrgType] = useState(props.orgType);
    const [id, setId] = useState(props.id);
    const [enabled, setEnabled] = useState(props.enabled);
    const [allLicenses, setAllLicenses] = useState(props.allLicenses);
    const [mode, setMode] = useState(props.mode);

    //for tags
    const [showNew, setShowNew] = useState(true);

    //mode flag
    const createMode = props.mode == 'create';
    const editMode = props.mode == 'edit';
    const summaryMode = props.mode == 'summary';

    //internal org flag
    const internalOrg = props.orgType == 'internal';

    //plan props
    const credits = props.credits;
    const machines = props.machines;
    const internalPlan = props.internal;
    const promotionalAccount = props.promotionalValue;
    const fairQueuing = props.fairQueuing;
    const dates = props.dates;
    const software = props.software;
    const tags = props.tags;

    //build up the state to present in the form (if create mode, use the defaults. Otherwise, load up the existing data)
    const planDetails = {
        machines: !createMode && machines ? Object.keys(machines) : defaults.machines,
        internal: !createMode ? internalPlan : defaults.internal,
        promotional: promotionalAccount ? true : false,
        promotionalValue: !createMode ? promotionalAccount : undefined,
        credits: {
            monthly: !createMode && 'monthly' in credits ? credits['monthly'] : defaults.credits.monthly,
            available: !createMode && 'available' in credits ? credits['available'] : defaults.credits.available,
            unlimited: !createMode && 'unlimited' in credits ? credits['unlimited'] : defaults.credits.unlimited,
        },
        fairQueuing: {
            multiplier: {
                active:
                    !createMode && 'multipliers' in fairQueuing && 'active' in fairQueuing['multipliers']
                        ? fairQueuing['multipliers']['active']
                        : defaults.fairQueuing.multiplier.active,
                default:
                    !createMode && 'multipliers' in fairQueuing
                        ? fairQueuing['multipliers']['default']
                        : defaults.fairQueuing.multiplier.default,
                extended:
                    !createMode && 'multipliers' in fairQueuing && 'extended' in fairQueuing['multipliers']
                        ? fairQueuing['multipliers']['extended']
                        : defaults.fairQueuing.multiplier.extended,
            },
            extended:
                !createMode && 'extended' in fairQueuing ? fairQueuing['extended'] : defaults.fairQueuing.extended,
        },
        dates: {
            scheduledStart:
                !createMode && 'activation-date' in dates
                    ? getDate(dates['activation-date']) !== ''
                    : defaults.dates.scheduledStart,
            scheduledEnd:
                !createMode && 'deactivation-date' in dates
                    ? getDate(dates['deactivation-date']) !== ''
                    : defaults.dates.scheduledEnd,
            refreshEnabled:
                !createMode && 'refresh-enabled' in dates ? dates['refresh-enabled'] : defaults.dates.refreshEnabled,
            refreshStopMode:
                !createMode && 'refresh-stop-mode' in dates
                    ? dates['refresh-stop-mode']
                    : defaults.dates.refreshStopMode,
            activationDate:
                !createMode && 'activation-date' in dates
                    ? getDate(dates['activation-date'])
                    : defaults.dates.activationDate,
            refreshStartDate:
                !createMode && 'refresh-start-date' in dates
                    ? getDate(dates['refresh-start-date'])
                    : defaults.dates.refreshStartDate,
            refreshStopDate:
                !createMode && 'refresh-stop-date' in dates
                    ? getDate(dates['refresh-stop-date'])
                    : defaults.dates.refreshStopDate,
            refreshOcccurences:
                !createMode && 'refresh-occurrences' in dates
                    ? dates['refresh-occurrences']
                    : defaults.dates.refreshOcccurences,
            deactivationDate:
                !createMode && 'deactivation-date' in dates
                    ? getDate(dates['deactivation-date'])
                    : defaults.dates.deactivationDate,
            createDate:
                !createMode && 'create-date' in dates ? getDate(dates['create-date']) : defaults.dates.createDate,
            lastUpdate:
                !createMode && 'last-update' in dates ? getDate(dates['last-update']) : defaults.dates.lastUpdate,
        },
        software: {
            name: !createMode && 'name' in software ? software['name'] : defaults.software.name,
            licenses: !createMode && 'licenses' in software ? software['licenses'] : defaults.software.licenses,
            customerType:
                !createMode && 'customer-type' in software ? software['customer-type'] : defaults.software.customerType,
            expirationDate:
                !createMode && 'expiration-date' in software
                    ? getDate(software['expiration-date'])
                    : defaults.software.expirationDate,
            duration: !createMode && 'duration' in software ? software['duration'] : defaults.software.duration, //assume a 30  day trial
            licenseEndMode: !createMode ? getLicenseEndMode(software) : defaults.software.licenseEndMode,
        },
        tags: {
            data: !createMode && tags !== undefined && tags.length > 0 ? tags : defaults.tags.data,
        },
    };

    //general
    const [machineNames, setMachineNames] = useState(planDetails.machines);
    const [promotionalValue, setPromotionalValue] = useState(planDetails.promotionalValue);
    const [internal, setInternal] = useState(planDetails.internal);
    const [promotional, setPromotional] = useState(planDetails.promotional);
    const [unlimited, setUnlimited] = useState(planDetails.credits.unlimited);
    const [planBalance, setPlanBalance] = useState(planDetails.credits.available);
    const [monthlyBalance, setMonthlyBalance] = useState(planDetails.credits.monthly);

    //general - error
    const [machineError, setMachineError] = useState('');
    const [promotionalError, setPromotionalError] = useState('');
    const [internalError, setInternalError] = useState('');
    const [planBalanceError, setPlanBalanceError] = useState('');
    const [monthlyBalanceError, setMonthlyBalanceError] = useState('');

    //fair queuing
    const [fqMultiplier, setFQMultiplier] = useState(planDetails.fairQueuing.multiplier.active);
    const [fqMultiplierDefault, setFQMultiplierDefault] = useState(planDetails.fairQueuing.multiplier.default);
    const [extended, setExtended] = useState(planDetails.fairQueuing.extended);
    const [fqMultiplierExtended, setFQMultiplierExtended] = useState(planDetails.fairQueuing.multiplier.extended);

    //fair queuing - error
    const [fqError, setFQError] = useState('');
    const [fqErrorDefault, setFQErrorDefault] = useState('');
    const [fqErrorExtended, setFQErrorExtended] = useState('');

    // dates
    const [scheduledStart, setScheduledStart] = useState(planDetails.dates.scheduledStart);
    const [scheduledEnd, setScheduledEnd] = useState(planDetails.dates.scheduledEnd);
    const [activationDate, setActivationDate] = useState(planDetails.dates.activationDate);
    const [deactivationDate, setDeactivationDate] = useState(planDetails.dates.deactivationDate);
    const [refreshEnabled, setRefreshEnabled] = useState(planDetails.dates.refreshEnabled);
    const [refreshStartDate, setRefreshStartDate] = useState(planDetails.dates.refreshStartDate); //default to the first of the next month
    const [refreshStopMode, setRefreshStopMode] = useState(planDetails.dates.refreshStopMode);
    const [refreshStopDate, setRefreshStopDate] = useState(planDetails.dates.refreshStopDate);
    const [refreshOccurrences, setRefreshOccurrences] = useState(planDetails.dates.refreshOcccurences);
    const [useAlternativeTimeZone, setUseAlternativeTimeZone] = useState(false);
    const [createDate, setCreateDate] = useState(planDetails.dates.createDate);
    const [lastUpdate, setLastUpdate] = useState(planDetails.dates.lastUpdate);
    //dates - error
    const [activationDateError, setActivationDateError] = useState('');
    const [deactivationDateError, setDeactivationDateError] = useState('');
    const [refreshEnabledError, setRefreshEnabledError] = useState('');
    const [refreshStartDateError, setRefreshStartDateError] = useState('');
    const [refreshStopDateError, setRefreshStopDateError] = useState('');
    const [refreshOccurrencesError, setRefreshOccurrencesError] = useState('');

    //software
    const [softwareName, setSoftwareName] = useState(planDetails.software.name);
    const [licenses, setLicenses] = useState(planDetails.software.licenses);
    const [licenseEndMode, setLicenseEndMode] = useState(planDetails.software.licenseEndMode);
    const [softwareDuration, setSoftwareDuration] = useState(planDetails.software.duration);
    const [customerType, setCustomerType] = useState(planDetails.software.customerType);
    const [softwareExpirationDate, setSoftwareExpirationDate] = useState(planDetails.software.expirationDate);

    //software - error
    const [softwareError, setSoftwareError] = useState('');
    const [licenseError, setLicenseError] = useState('');
    const [customerTypeError, setCustomerTypeError] = useState('');
    const [softwareDurationError, setSoftwareDurationError] = useState('');
    const [softwareExpirationDateError, setSoftwareExpirationDateError] = useState('');

    //tags
    const [tagData, setTagData] = useState(planDetails.tags.data);
    const [tagError, setTagError] = useState('');

    //other
    const [useLocalTimeZone, setUseLocalTimeZone] = useState(true);
    const [minuteStep, setMinuteStep] = useState(60);
    const [timeZone, setTimeZone] = useState(moment.tz.guess(true));

    const steps = {
        general: 0,
        fairQueuing: 1,
        dates: 2,
        software: 3,
        tags: 4,
        review: 5,
    };

    const [step, setStep] = useState(steps.general);

    function handleMachine(machines) {
        setMachineError('');
        setMachineNames(machines);
    }

    // Handlder to Promotional Value
    function handlePromotionalValue(value) {
        setPromotionalValue(value);

        // add new tag for this as well
        let remainingOptions = getRemainingOptions();

        // check if this tag already exists and we only need to update it's value
        let tagIndex = promotionalPlanTagIndex();
        if (tagIndex >= 0) {
            updatePendingTagValue(value, tagIndex);
        } else {
            tagData.push({ key: 'Promotional Account', value: value, state: 'pending', options: remainingOptions });
            setTagData(tagData);
        }
    }

    // Finds and returns the index of a tag object
    function promotionalPlanTagIndex() {
        let tagIndex = -1;
        if (tagData !== undefined) {
            tagData.forEach((value, index) => {
                if (value['key'] == 'Promotional Account') {
                    tagIndex = index;
                }
            });
        }
        return tagIndex;
    }

    // Handler for Promotional Toggle
    function handlePromotionalToggle(value) {
        setPromotional(value);
        if (!value) {
            // clear any tagging
            removePromotionalTag();
            // clear promotional value
            setPromotionalValue(undefined);
        }
    }

    // Checks if promotional tag exists and removes it
    function removePromotionalTag() {
        if (tagData.length !== undefined) {
            tagData.forEach((value, index) => {
                if (value['key'] === 'Promotional Account') {
                    removePendingTag(index);
                }
            });
        }
    }

    function handleTimeZone(tz) {
        setTimeZone(tz);

        //clear out some errors
        setRefreshStartDateError('');
    }

    function getExpirationMessage() {
        const message = [];
        if (hasDate(softwareExpirationDate)) {
            //check if expiration date has passed
            let expired = moment() >= moment(softwareExpirationDate);

            if (!expired) {
                let days = getTimeRemainingInDays(softwareExpirationDate);
                //license has not expired
                message.push(
                    <div>
                        All user licenses will expire in <b>{Math.abs(days)}</b> day(s)
                    </div>,
                );
            } else {
                //license has expired
                message.push(
                    <div>
                        Licenses have already <b>expired</b>
                    </div>,
                );
            }
        } else {
            message.push(
                <div>
                    All user licenses will expire in <b>TBD</b> day(s)
                </div>,
            );
        }
        return message;
    }

    function getAllowedValues(tag) {
        let allowed = [];

        if (tag !== undefined && props.allTags.length > 0) {
            let found = props.allTags.filter(function (e) {
                return e.key == tag;
            });

            if (found !== undefined && found.length == 1) {
                found = found[0];
                if ('allowed' in found && found['allowed'].length > 0) {
                    allowed = found['allowed'];
                }
            }
        }

        return allowed;
    }

    function genMachineOptions() {
        let machineList = [];
        props.allMachines.forEach((machine) => {
            //otherwise, add the machine to the list
            machineList.push({ value: machine.name, text: machine.name });
        });

        return (
            <Select
                options={machineList}
                onChange={(values) => handleMachine(values)}
                fluid={true}
                search={true}
                multiple={true}
                key="plan"
                id="short-select"
                placeholder="Select Machine(s)"
                indicator="required"
                label="Machine"
                value={machineNames}
                error={machineError}
            />
        );
    }

    function genPromotionalOptions() {
        let promotionalOptionsList = [];
        ['Academic', 'Corporate'].forEach((option) => {
            promotionalOptionsList.push({ value: option, text: option });
        });

        return (
            <Select
                options={promotionalOptionsList}
                onChange={(values) => handlePromotionalValue(values)}
                fluid={true}
                search={true}
                key="promotional"
                id="short-select"
                placeholder="Select Value"
                indicator="required"
                label="Promotional Account Value"
                value={promotionalValue}
                error={promotionalError}
            />
        );
    }

    function addNewTag() {
        setShowNew(!showNew);
        if (tagData.length < maxTags) {
            //dynamically adjust the list of remaining options based on what they've selected
            let remainingOptions = getRemainingOptions();
            tagData.push({ key: '', value: '', state: 'pending', options: remainingOptions });
            setTagData(tagData);
        }
    }

    function getRemainingOptions() {
        let options = [];
        if (props.allTags !== undefined) {
            //create a set of the keys
            let keys = new Set();
            tagData.forEach((t) => {
                keys.add(t['key']);
            });

            props.allTags.forEach((t) => {
                //check if duplicate
                if (!keys.has(t['key'])) {
                    //add it if it' doesn't already exist
                    options.push({ value: t.key, text: t.key });
                }
            });
        }

        return options;
    }

    function updatePendingTagValue(value, index) {
        let item = tagData[index];
        item['value'] = value;

        // update promotional value if needed
        if (item['key'] === 'Promotional Account') {
            setPromotional(true);
            setPromotionalValue(value);
        }

        let newTags = [...tagData];
        newTags[index] = item;

        setTagData(newTags);

        //clear out the error
        setTagError('');
    }

    function updatePendingTagKey(index, key) {
        let item = tagData[index];

        item['key'] = key;

        let newTags = [...tagData];
        newTags[index] = item;

        setTagData(newTags);

        //clear out the error
        setTagError('');
    }

    function removePendingTag(index) {
        let newTags = [...tagData];

        // update promotional value if needed
        if (newTags[index]['key'] === 'Promotional Account') {
            setPromotional(false);
            setPromotionalValue(undefined);
        }

        if (editMode) {
            if ('saved' in newTags[index] && newTags[index]['saved']) {
                newTags[index]['state'] = 'remove';
            } else {
                newTags.splice(index, 1);
            }
        } else {
            newTags.splice(index, 1);
        }

        setTagData(newTags);

        //clear out the error
        setTagError('');
    }

    function undoRemovalPendingTag(index) {
        let newTags = [...tagData];

        //check if this tag we want to remove has a real-id
        if (editMode) {
            if (tagData[index]['state'] == 'remove') {
                delete newTags[index]['state'];
            }
        }

        setTagData(newTags);
        //validateTagData(tagData, false)
    }

    function generateTags() {
        let tagList = <div></div>;
        const tags = [];

        //initalize an empty tag

        if (!isNullOrEmpty(tagData)) {
            tagData.forEach((t, index) => {
                if (editMode && t['state'] !== 'pending') {
                    t['saved'] = true;
                }

                tags.push(
                    <Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ 'padding-right': '10px', width: '100%' }}>
                                {'saved' in t && t['saved'] ? (
                                    <div>
                                        <InputLabel
                                            label="Key (Saved)"
                                            indicator="required"
                                            title="This field is read only"
                                        />
                                        <Input type="text" value={t['key']} readOnly title="This field is read only" />
                                    </div>
                                ) : (
                                    <Select
                                        options={t['options']}
                                        value={t['key']}
                                        onChange={(value) => updatePendingTagKey(index, value)}
                                        fluid={true}
                                        search={true}
                                        multiple={false}
                                        key={t['key'] + '-' + t['error']}
                                        id="short-select"
                                        placeholder="Select Key"
                                        label="Key"
                                        indicator={'required'}
                                        error={t['error']}
                                    />
                                )}
                            </div>

                            <div style={{ 'padding-left': '10px', width: '100%' }}>
                                {'state' in t && t['state'] == 'remove' ? (
                                    <div>
                                        <br />
                                        <p>This tag is marked for removal</p>
                                    </div>
                                ) : (
                                    <div>{genAllowedTagValues(t['key'], t['value'], index)}</div>
                                )}
                            </div>
                            <div style={{ 'padding-left': '10px', width: '100%' }}>
                                <br />
                                {'state' in t && t['state'] == 'remove' ? (
                                    <Button type="primary" onClick={() => undoRemovalPendingTag(index)}>
                                        <Icon root="common" name="undo" size="small"></Icon>
                                        &nbsp;&nbsp;Undo
                                    </Button>
                                ) : (
                                    <Button type="primary" onClick={() => removePendingTag(index)}>
                                        <Icon root="common" name="delete" size="small"></Icon>
                                        &nbsp;&nbsp;Remove
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Form.Group>,
                );
            });
        }

        tagList = <div>{tags}</div>;

        return tagList;
    }

    function genAllowedTagValues(key, value, index) {
        let allowedList = [];

        //get the allowed list
        let allowed = getAllowedValues(key);

        //build out the dropdown values
        if (allowed !== undefined) {
            allowed.forEach((t) => {
                //otherwise, add the tag to the list
                allowedList.push({ value: t, text: t });
            });

            return (
                <Select
                    options={allowedList}
                    onChange={(newValue) => updatePendingTagValue(newValue, index)}
                    value={value}
                    fluid={true}
                    search={true}
                    multiple={false}
                    key="tag-value"
                    id="short-select"
                    placeholder="Select Value"
                    indicator="required"
                    label="Value"
                    //error={error}
                />
            );
        }
    }

    function prepareTags() {
        let tags = [];
        if (tagData !== undefined && tagData.length > 0) {
            tagData.forEach((t) => {
                let item = {
                    key: t['key'],
                    value: t['value'],
                };

                //exclude the ones that are marked for removal
                //their absense in the request implies they should be removed

                if (t['state'] == undefined || ('state' in t && t['state'] != 'remove')) {
                    tags.push(item);
                }
            });
        }

        return tags;
    }

    function clearInputs() {
        //general
        setMachineNames(planDetails.machines);
        setInternal(planDetails.internal);
        setUnlimited(planDetails.credits.unlimited);
        setMonthlyBalance(planDetails.credits.monthly);
        setPlanBalance(planDetails.credits.available);
        setPromotional(planDetails.promotional);
        setPromotionalValue(planDetails.promotionalAccount);
        setMachineError('');
        setInternalError('');
        setMonthlyBalanceError('');
        setPlanBalanceError('');
        setPromotionalError('');

        //fair queuing
        setFQMultiplier(planDetails.fairQueuing.multiplier.active);
        setFQMultiplierDefault(planDetails.fairQueuing.multiplier.default);
        setFQMultiplierExtended(planDetails.fairQueuing.multiplier.extended);
        setExtended(planDetails.fairQueuing.extended);
        setFQError('');
        setFQErrorDefault('');
        setFQErrorExtended('');

        //dates
        setScheduledStart(planDetails.dates.scheduledStart);
        setScheduledEnd(planDetails.dates.scheduledEnd);
        setActivationDate(planDetails.dates.activationDate);
        setRefreshEnabled(planDetails.dates.refreshEnabled);
        setRefreshStartDate(planDetails.dates.refreshStartDate);
        setRefreshStopMode(planDetails.dates.refreshStopMode);
        setRefreshStopDate(planDetails.dates.refreshStopDate);
        setRefreshOccurrences(planDetails.dates.refreshOcccurences);
        setDeactivationDate(planDetails.dates.deactivationDate);
        setCreateDate(planDetails.dates.createDate);
        setLastUpdate(planDetails.dates.lastUpdate);
        setActivationDateError('');
        setDeactivationDateError('');
        setRefreshEnabledError('');
        setRefreshStartDateError('');
        setRefreshStopDateError('');
        setRefreshOccurrencesError('');

        //software
        setSoftwareName(planDetails.software.name);
        setLicenses(planDetails.software.licenses);
        setCustomerType(planDetails.software.customerType);
        setSoftwareDuration(planDetails.software.duration);
        setLicenseEndMode(planDetails.software.licenseEndMode);
        setSoftwareExpirationDate(planDetails.software.expirationDate);
        setLicenseError('');
        setCustomerTypeError('');
        setSoftwareExpirationDateError('');

        //tags
        setTagData(planDetails.tags.data);
        setTagError('');

        //clear out some other fields
    }

    function handleClose() {
        setShow(false);
        setTimeZone(moment.tz.guess(true));
        setStep(steps.general);
        clearInputs();
    }

    function handleAdd() {
        if (validateAllSteps()) {
            let dateFormat = 'YYYY-MM-DD[T]HH:mm:ss';

            let body = {
                machines: machineNames,
                internal: internal,
                credits: {
                    available: !unlimited ? planBalance : 0,
                    monthly: !unlimited && refreshEnabled ? monthlyBalance : 0,
                    unlimited: unlimited,
                },
                'fair-queuing': {
                    multipliers: {
                        active: fqMultiplier,
                    },
                    extended: extended, // by default
                },
                dates: {
                    'activation-date': scheduledStart ? moment.utc(activationDate).format(dateFormat) : '', //empty will tell the backend to remove this flag after update
                    'deactivation-date': scheduledEnd ? moment.utc(deactivationDate).format(dateFormat) : '', //empty will tell the backend to remove this flag after update
                    'refresh-enabled': refreshEnabled,
                }, //TBD since it's highly dynamic
                software: {
                    name: hasSoftware() ? softwareName : '', //empty will tell the backend to not add this to the plan/ or remove this flag from the plan on an update
                }, // assume no software is needed
                tags: prepareTags(),
            };

            if (promotional) {
                body['promotional-value'] = promotionalValue;
            }

            if (extended) {
                body['fair-queuing']['multipliers']['default'] = fqMultiplierDefault;
                body['fair-queuing']['multipliers']['extended'] = fqMultiplierExtended;
            }

            // if not unlimited credits, then add the refresh start date and other refresh params
            if (refreshEnabled) {
                body['dates']['refresh-start-date'] = moment.utc(refreshStartDate).format(dateFormat);

                body['dates']['refresh-stop-mode'] = refreshStopMode;

                if (refreshStopMode !== refreshEndModes.none) {
                    body['dates']['refresh-stop-mode'] = refreshStopMode;
                    //get the stop by date or by occurrences
                    if (refreshStopMode == refreshEndModes.date) {
                        body['dates']['refresh-stop-date'] = moment.utc(refreshStopDate).format(dateFormat);
                        //ignoring occurrences since that's calculated from the start vs. stop date
                    } else if (refreshStopMode == refreshEndModes.occurrences) {
                        body['dates']['refresh-occurrences'] = refreshOccurrences;
                        //ignoring the refresh-stop-date since that's calculated from the refresh occurrences
                    }
                }
            }

            if (hasSoftware()) {
                body['software'] = {
                    name: softwareName,
                    licenses: licenses,
                    'customer-type': customerType,
                };

                // capture the way this license is meant to expire
                // this  will be useful if we want to support dates or periods for all customer types instead of it being only one type

                //handle the expiration and duration based on customer type
                if (licenseEndMode == licenseEndModes.date) {
                    body['software']['expiration-date'] = moment.utc(softwareExpirationDate).format(dateFormat);
                    body['software']['duration'] = 0;
                } else {
                    body['software']['expiration-date'] = '';
                    body['software']['duration'] = softwareDuration;
                }
            }

            if (createMode) {
                HQS_API.addOrgMachinePlan(props.org, body)
                    .then((response) => {
                        const title = 'Plan added';
                        const details = `Plan was added to ${props.org} successfully!`;
                        toast(
                            <ToastNotification closeToast={false} title={title} details={details} severity="success" />,
                        );

                        if (props.callback) {
                            props.callback();
                        }
                        handleClose();
                    })
                    .catch((error) => {
                        const title = 'Error!';
                        var details = 'Unkown Error!';
                        if (error.response !== undefined) {
                            details = error.response.data.error.text;
                        }
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    })
                    .finally(() => {
                        //handleClose()
                    });
            } else if (editMode) {
                //update the machines to be machine-list

                // update org's plan
                HQS_API.updateOrgPlan(props.org, props.id, body)
                    .then((response) => {
                        if (props.callBack) {
                            props.callBack();
                        }
                        const title = 'Plan updated';
                        const details = `Plan was updated for ${props.org} successfully!`;
                        toast(
                            <ToastNotification closeToast={false} title={title} severity="success" details={details} />,
                        );
                        handleClose();
                    })
                    .catch((error) => {
                        console.log(error);
                        const title = 'Error!';
                        var details = 'Unknown Error!';
                        if (error.response !== undefined) {
                            details = error.response.data.error.text;
                        }
                        toast(
                            <ToastNotification
                                closeToast={false}
                                title={title}
                                details={details}
                                severity="critical"
                            />,
                        );
                    })
                    .finally(() => {
                        //handleClose()
                    });
            }
        }
    }

    function handleDeactivationDate(date) {
        //make sure we zero out the seconds
        date.setSeconds(0);

        setDeactivationDate(date);
        setDeactivationDateError('');

        if (refreshEnabled) {
            //clear out the refresh date errors
            setRefreshStartDateError('');
            if (refreshStopMode == refreshEndModes.date) {
                setRefreshStopDateError('');
            }
        }
    }

    function handleSoftwareExpirationDate(date) {
        //make sure we zero out the seconds
        date.setSeconds(0);

        setSoftwareExpirationDate(date);
        setSoftwareExpirationDateError('');

        //check if there's a deactivation date for the plan already
        if (scheduledEnd && deactivationDate) {
            if (moment(date).isAfter(moment(deactivationDate))) {
                setSoftwareExpirationDateError('Must be before or same as deactivation date');
            }
        }
    }

    function handleRefreshStartDate(date) {
        //make sure we zero out the seconds
        date.setSeconds(0);

        setRefreshStartDate(date);

        //consider the timezone, otherwise we create problems if the user picks a non-local timezone
        if (moment(date).tz(timeZone).date() >= 29) {
            setRefreshStartDateError('Must be in first 28 days');
        } else if (!scheduledStart && moment(date).isBefore(moment(activationDate))) {
            //must be after activation date
            //must be before deactivation date
            setRefreshStartDateError("Must after the plan's activation date");
        } else {
            //make sure the day is 1-28
            setRefreshStartDateError('');
        }

        if (refreshStopMode == refreshEndModes.date) {
            //if the date is corrected, lets clean up the error
            if (moment(date).isBefore(refreshStopDate)) {
                //must be after activation date
                //must be before deactivation date
                setRefreshStopDateError('');
            }
        }
    }

    function handleRefreshStopDate(date) {
        //make sure we zero out the seconds
        date.setSeconds(0);

        setRefreshStopDate(date);

        if (moment(date).isSameOrBefore(refreshStartDate)) {
            //must be after activation date
            //must be before deactivation date
            setRefreshStopDateError('Must be after refresh date');
        } else {
            //make sure the day is 1-28
            setRefreshStopDateError('');
        }
    }

    function handleStartDate(date) {
        //make sure we zero out the seconds
        date.setSeconds(0);

        setActivationDate(date);
        setActivationDateError('');
    }

    function getTimeZoneList() {
        let zones = [];
        var timeZones = moment.tz.names();
        Object.entries(timeZones).forEach(([k, v]) => {
            const item = '(UTC' + moment.tz(v).format('Z') + ') ' + v;
            zones.push({ value: v, text: item });
        });

        zones.sort((a, b) => a.text.localeCompare(b.text));

        return (
            <Select
                placeholder="Time Zone"
                options={zones}
                value={timeZone}
                onChange={(text) => handleTimeZone(text)}
                fluid={true}
                search={true}
                key="timezone"
            />
        );
    }

    function handleCustomerType(type) {
        setCustomerType(type);

        if (scheduledEnd) {
            if (deactivationDate) {
                setSoftwareExpirationDate(deactivationDate);
                setSoftwareExpirationDateError('');
            }
        }

        //right now only trial supports duration
        setLicenseModeByCustomer(type);
        setCustomerTypeError('');
    }

    function handleSoftware(software) {
        setCustomerTypeError('');
        setSoftwareName(software);

        //if the org already has a license for this software then fill in the rest of the information
        let license = getSoftwareLicense(software);

        //check if the org has license details setup already for this software
        if (!isNullOrEmpty(license)) {
            setLicenses(license['max']);
            setCustomerType(license['customer']);
            setSoftwareExpirationDate(getDate(license['expiration']));
            setSoftwareDuration(license['duration']);
            setLicenseModeByCustomer(license['customer']);
        }
    }

    function setLicenseModeByCustomer(customer) {
        //right now only trial supports duration
        if (customer == 'trial') {
            setLicenseEndMode(licenseEndModes.duration);
        } else {
            //right  now only the other customer types support the date
            setLicenseEndMode(licenseEndModes.date);
        }
    }

    function generateCustomerTypes() {
        let customerList = <div></div>;
        const customer = [];

        let options = [];
        if (customerTypes !== undefined) {
            Object.keys(customerTypes).map((key, index) =>
                //otherwise, add the tag to the list
                options.push({ value: key, text: customerTypes[key] }),
            );
        }

        customer.push(
            <Select
                options={options}
                value={customerType}
                onChange={(value) => handleCustomerType(value)}
                fluid={true}
                search={true}
                multiple={false}
                id="short-select"
                placeholder="Select Customer Type"
                error={customerTypeError}
            />,
        );

        customerList = (
            <div title="The type of customer dictates how user agreements are enforced" style={{ width: '60%' }}>
                {customer}
            </div>
        );

        return customerList;
    }

    function generateSoftware() {
        let softwareList = <div></div>;
        const sw = [];

        let options = [{ value: 'none', text: 'None' }];

        if (softwareMap !== undefined) {
            Object.keys(softwareMap).map((key, index) => {
                //otherwise, add the tag to the list

                //let existing = !isNullOrEmpty(props.allLicenses) && props.allLicenses[key] ? true : false;

                options.push({ value: key, text: softwareMap[key] });
            });
        }

        sw.push(
            <Select
                options={options}
                value={softwareName}
                onChange={handleSoftware}
                fluid={true}
                search={true}
                id="short-select"
                placeholder="Select Software"
                label="Software"
                error={softwareError}
            />,
        );

        softwareList = (
            <div title="Software available in this plan" style={{}}>
                {sw}
            </div>
        );

        return softwareList;
    }

    function hasSoftware() {
        if (softwareName != undefined && isLicensedPlan(softwareName)) {
            return true;
        } else {
            return false;
        }
    }

    function getSoftwareLicense(software) {
        let license = {};

        if (!isNullOrEmpty(props.allLicenses)) {
            let licenses = props.allLicenses.filter((s) => s.software === software);

            if (licenses && licenses.length > 0) {
                license = licenses[0];
            }
        }

        return license;
    }
    function hasSoftwareLicense(software) {
        let license = getSoftwareLicense(software);

        if (license) {
            return true;
        } else {
            return false;
        }
    }

    function getCustomerTypeMessage(type) {
        let message = '';
        if (type !== undefined && type !== '') {
            //get auth challenge based on plan and customer
            let authChallenge = getSoftwareCustomerChallenge(softwareName, customerType);

            if (customerType == 'direct') {
                message = (
                    <span>
                        The{' '}
                        <a className="forgot-password-link" href={getEula(softwareName, authChallenge)} target="_blank">
                            End User License Agreement (EULA)
                        </a>{' '}
                        is <b>not required</b> for all users. Already signed in contract
                    </span>
                );
            } else if (customerType == 'beta') {
                message = (
                    <span>
                        The{' '}
                        <a className="forgot-password-link" href={getEula(softwareName, authChallenge)} target="_blank">
                            Beta End User License Agreement (EULA)
                        </a>{' '}
                        is <b>required</b> for all users.
                    </span>
                );
            } else if (customerType == 'trial') {
                message = (
                    <span>
                        The{' '}
                        <a className="forgot-password-link" href={getEula(softwareName, authChallenge)} target="_blank">
                            Trial End User License Agreement (EULA)
                        </a>{' '}
                        is <b>required</b> for all users.
                    </span>
                );
            } else {
                message = (
                    <span>
                        The{' '}
                        <a className="forgot-password-link" href={getEula(softwareName, authChallenge)} target="_blank">
                            End User License Agreement (EULA)
                        </a>{' '}
                        is <b>required</b> for all users
                    </span>
                );
            }
        }
        return message;
    }

    function updateMultiplier(multiplier) {
        //clear out the error message when the user starts typing
        setFQMultiplier(Number(multiplier));
        validate(multiplier);
    }

    function updateMultiplierDefault(multiplier) {
        //clear out the error message when the user starts typing
        setFQMultiplierDefault(Number(multiplier));
        validateDefault(multiplier);
    }

    function updateMultiplierExtended(multiplier) {
        //clear out the error message when the user starts typing
        setFQMultiplierExtended(Number(multiplier));
        validateExtended(multiplier);
    }

    function updateMonthlyBalance(balance) {
        //clear out the error message when the user starts typing
        setMonthlyBalance(Number(balance));
        if (balance <= 0) {
            setMonthlyBalanceError('Must be greater than 0');
        } else {
            setMonthlyBalanceError('');
        }
    }

    function updatePlanBalance(balance) {
        //clear out the error message when the user starts typing
        setPlanBalance(Number(balance));
        if (balance < 0) {
            setPlanBalanceError('Must be 0 or greater');
        } else {
            setPlanBalanceError('');
        }
    }

    function updateLicenses(licenses) {
        setLicenses(Number(licenses));
        if (licenses <= 0) {
            setLicenseError('Must be greater than 0');
        } else {
            setLicenseError('');
        }
    }

    function updateRefreshOccurrences(count) {
        setRefreshOccurrences(Number(count));
        if (count < 0) {
            setRefreshOccurrencesError('Must be greater than or equal to 0');
        } else {
            setRefreshOccurrencesError('');
        }
    }

    function isValid(value) {
        // is a a positive number
        if (!isNaN(value) && Number(value) > 0) {
            return true;
        } else {
            return false;
        }
    }

    function validate(value) {
        // make sure the multiplier is a postive number
        if (isValid(value)) {
            setFQError('');
        } else {
            setFQError('Must be a positive number');
        }
    }

    function validateDefault(value) {
        // make sure the multiplier is a postive number
        if (isValid(value)) {
            setFQErrorDefault('');
        } else {
            setFQErrorDefault('Must be a positive number');
        }
    }

    function validateExtended(value) {
        // make sure the multiplier is a postive number
        if (isValid(value)) {
            if (value <= fqMultiplierDefault) {
                setFQErrorExtended('Must be greater than the default multiplier');
            } else {
                setFQErrorExtended('');
            }
        } else {
            setFQErrorExtended('Must be a positive number');
        }
    }

    function handleInternal(checked) {
        //set dates to start and end of day.
        //only set start time if it's not selected already
        setInternal(checked);
    }

    function handleUnlimited(checked) {
        //set dates to start and end of day.
        //only set start time if it's not selected already
        setUnlimited(checked);
        setExtended(false);

        //set plan balance to zero so we clean up the old avilable hqc
        setPlanBalance(0);

        //set monhtly balance to zero since it's irrelevant with unlimited credits
        setMonthlyBalance(0);

        setRefreshEnabled(false);

        setPlanBalanceError('');
        setMonthlyBalanceError('');
        setRefreshEnabledError('');
    }

    function handledExtended(checked) {
        //set dates to start and end of day.
        //only set start time if it's not selected already
        //setUnlimited(false);
        setExtended(checked);

        //set the default to be the same as the active
        setFQMultiplierDefault(fqMultiplier);

        //set refresh enabled to true since we need a date to refresh/revert back to defaults
        //do this if it's not already checked, if it is, don't change it
        if (checked && !refreshEnabled) {
            setRefreshEnabled(checked);
        }
        setRefreshEnabledError('');
    }

    function handleScheduledStart(checked) {
        //set dates to start and end of day.
        //only set start time if it's not selected already
        setScheduledStart(checked);
    }

    function handleScheuledEnd(checked) {
        //set dates to start and end of day.
        //only set start time if it's not selected already
        setScheduledEnd(checked);
    }

    function handleRefreshEndMode(eventValue) {
        //if the refresh start date is empty
        if (refreshStartDate == '') {
            if (eventValue != refreshEndModes.none) {
                setRefreshStartDateError('Missing required date');
            }
        } else {
            setRefreshStartDateError('');
        }
        setRefreshStopMode(eventValue);
    }

    function handleUseLocalTimeZone(checked) {
        //set dates to start and end of day.
        //only set start time if it's not selected already
        setTimeZone(moment.tz.guess(false));
        setUseLocalTimeZone(checked);
    }

    function handleRefreshEnabled(checked) {
        setRefreshEnabled(checked);
        setRefreshEnabledError('');
    }

    function handleAlternativeTimeZone(checked) {
        setUseAlternativeTimeZone(checked);
        if (!checked) {
            //set the time zone back to the local time zone so we can update the date pickers
            setTimeZone(moment.tz.guess(true));
        }
    }

    function showGeneralForm() {
        return (
            <div>
                <Form.Group>
                    <h4>Device Access</h4>
                    <p>Control access to physical hardware, emulators, syntax checkers, and/or other devices.</p>
                    <div style={{ 'max-height': '80px' }}>{genMachineOptions()}</div>
                    <div>
                        <p>
                            Allow <b>internal</b> only features (e.g. custom job priorities)?
                        </p>
                        <Checkbox
                            label={internal ? 'Yes' : 'No'}
                            toggle={true}
                            checked={internal}
                            disabled={!internalOrg}
                            onChange={handleInternal}
                        />
                        <p>
                            <Badge color="blue">Note</Badge> This option is only available for <b>internal</b> orgs.
                            This org's type: <b>{props.orgType}</b>
                        </p>
                    </div>
                </Form.Group>
                <div>
                    <p>
                        Is this a <b>promotional</b> plan (Academic, Corporate)?
                    </p>
                    <Checkbox
                        label={promotional ? 'Yes' : 'No'}
                        toggle={true}
                        checked={promotional}
                        onChange={handlePromotionalToggle}
                    />
                    <div
                        style={{
                            display: promotional ? '' : 'none',
                        }}>
                        {genPromotionalOptions()}
                    </div>
                    <p>
                        <Badge color="blue">Note</Badge> This option can also be set in the Tags section.
                    </p>
                </div>
                <Form.Group></Form.Group>

                <div>
                    <Form.Group>
                        <h4>Credits</h4>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                                style={{
                                    'padding-right': '10px',
                                }}>
                                <InputLabel label="Available HQC:" />
                                <p>The amount of credits available to use on this plan</p>
                                <Checkbox
                                    label="Unlimited"
                                    toggle={true}
                                    checked={unlimited}
                                    onChange={handleUnlimited}
                                />
                                {!unlimited ? (
                                    <div>
                                        <Input
                                            type="number"
                                            min="0"
                                            value={planBalance}
                                            onChange={(value) => updatePlanBalance(value)}
                                            disabled={unlimited}
                                            error={planBalanceError}
                                        />
                                        <p>
                                            <Badge color="blue">Note</Badge> This value changes as jobs are worked on to
                                            reflect the <b>current</b> amount of available credits. These credits can be
                                            automatically <b>refilled</b> according to a <b>Refresh Schedule</b> defined
                                            in the <b>Dates & Schedule</b> section
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </Form.Group>
                </div>
            </div>
        );
    }

    function showFairQueuingForm() {
        return (
            <div>
                <h4>Fair Queuing Multiplier</h4>
                <p>
                    Set the overall rate of HQC accumulation by weighting the job's original cost against this
                    multiplier.
                </p>
                <ul>
                    <li>
                        <p>
                            The <b>higher</b> this number the <b>greater</b> the cost = <b>slower queuing</b>
                        </p>
                    </li>
                    <li>
                        <p>
                            The <b>lower</b> this number the <b>smaller</b> the cost = <b>faster queuing</b>
                        </p>
                    </li>
                </ul>
                <Form.Group>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                            style={{
                                'padding-right': '20px',
                            }}>
                            <InputLabel label="Active:" indicator="required" />
                            <p>The current weight being applied to jobs</p>
                            <Input
                                type="number"
                                min={0}
                                value={fqMultiplier}
                                default={1}
                                onChange={(newFQMultiplier) => updateMultiplier(newFQMultiplier)}
                                placeholder="FQ Multiplier (Active)"
                                error={fqError}
                            />
                        </div>
                    </div>
                </Form.Group>
                <Form.Group>
                    <h4>Extended Access</h4>
                    <div title={unlimited ? 'Throttling is not applicable on plans with unlimited credits' : ''}>
                        <p>
                            Allow job submissions at a <b>throttled</b> (deprioritized) rate when <b>exceeds</b> the
                            monthly HQC balance?
                        </p>
                        <Checkbox
                            label={extended ? 'Yes' : 'No'}
                            toggle={true}
                            checked={extended}
                            disabled={unlimited}
                            onChange={handledExtended}
                        />
                        {extended ? (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div
                                    style={{
                                        'padding-right': '20px',
                                    }}>
                                    <InputLabel label="Throttled:" indicator="required" />
                                    <p>
                                        The weight to set as the <b>active</b> multiplier when when the available
                                        credits run out before the next refresh
                                    </p>
                                    <Input
                                        type="number"
                                        min={fqMultiplierDefault}
                                        default={20}
                                        value={fqMultiplierExtended}
                                        onChange={(newFQMultiplier) => updateMultiplierExtended(newFQMultiplier)}
                                        placeholder="FQ Multiplier (Throttled)"
                                        error={fqErrorExtended}
                                    />
                                    <InputLabel label="Default:" indicator="required" />
                                    <p>
                                        The weight to set as the <b>active</b> multiplier when the plan refreshes
                                    </p>
                                    <Input
                                        type="number"
                                        min={0}
                                        value={fqMultiplierDefault}
                                        max={fqMultiplierExtended}
                                        default={1}
                                        onChange={(newFQMultiplier) => updateMultiplierDefault(newFQMultiplier)}
                                        placeholder="FQ Multiplier (Default)"
                                        error={fqErrorDefault}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </Form.Group>
            </div>
        );
    }

    function showDatesForm() {
        return (
            <div>
                <Form.Group>
                    <h4>Dates & Schedule</h4>
                    <p>Control important dates and scheduling information on this plan</p>
                    {/* start time zone*/}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <div style={{ 'padding-right': '10px', width: '100%' }}>
                            <InputLabel label="Time Zone" indicator="optional" />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <div style={{ width: '55%', 'padding-right': '20px' }}>
                                    <p>
                                        Need to see dates in a <b>different</b> time zone?
                                    </p>
                                    <Checkbox
                                        label={useAlternativeTimeZone ? 'Yes' : 'No'}
                                        toggle={true}
                                        checked={useAlternativeTimeZone}
                                        onChange={handleAlternativeTimeZone}
                                    />
                                </div>
                                <div style={{ width: '45%', 'padding-left': '10px' }}>
                                    {useAlternativeTimeZone ? (
                                        getTimeZoneList()
                                    ) : (
                                        <div>
                                            <p>
                                                <b>Local Time:</b>
                                            </p>
                                            <p>{getTimeZoneSummary(moment.tz.guess(true))}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end time zone*/}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <div style={{ 'padding-right': '10px', width: '100%' }}>
                            <InputLabel label="Activation Date" indicator="optional" />
                            <p>
                                Should this plan <b>start</b> at a specific date/time? If <b>not</b>, the plan will take
                                effect
                                <b> immediately</b> after creation.
                            </p>
                            <Checkbox
                                label={scheduledStart ? 'Yes' : 'No'}
                                toggle={true}
                                checked={scheduledStart}
                                onChange={handleScheduledStart}
                            />

                            {scheduledStart ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    <div style={{ 'padding-right': '10px' }}>
                                        <DatePicker
                                            key={
                                                activationDate !== undefined
                                                    ? activationDate.toString() + '-expire' + timeZone
                                                    : 'expire'
                                            }
                                            timezone={timeZone}
                                            type="datetime"
                                            displayFormat="MM/DD/YY, h:mm a"
                                            placeholder="MM/DD/YY, h:mm a"
                                            minuteStep={minuteStep}
                                            disablePast={true}
                                            onChange={handleStartDate}
                                            value={activationDate}
                                            error={activationDateError}
                                        />
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <InputLabel
                        label="Refresh Schedule"
                        indicator={extended ? 'required' : 'optional'}
                        title="Should this plan refresh the available credits and reset any other plan defaults?"
                    />

                    {!unlimited ? (
                        <div>
                            {!extended ? (
                                <p>
                                    Should this plan to refresh the <b>available credits</b> on a regular basis?
                                </p>
                            ) : (
                                <p>
                                    Should this plan to refresh the <b>available credits</b> and the{' '}
                                    <b>active FQ multiplier</b> on a regular basis?
                                </p>
                            )}
                            <div title={extended ? 'Required when extended (throttled) access is enabled' : ''}>
                                <Checkbox
                                    label={refreshEnabled ? 'Yes' : 'No'}
                                    toggle={true}
                                    checked={refreshEnabled}
                                    onChange={handleRefreshEnabled}
                                />
                                <p style={{ color: '#be271C', fontWeight: 600 }}>{refreshEnabledError}</p>
                            </div>
                            {refreshEnabled ? (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                        <div
                                            style={{
                                                'padding-right': '20px',
                                                width: '55%',
                                            }}>
                                            <InputLabel label="Monthly HQC" indicator="required" />
                                            <p>The amount of credits to reset to every month</p>
                                            <Input
                                                type="number"
                                                min={0}
                                                value={monthlyBalance}
                                                onChange={(value) => updateMonthlyBalance(value)}
                                                disabled={unlimited}
                                                error={monthlyBalanceError}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                'padding-top': '20px',
                                                'padding-left': '10px',
                                            }}>
                                            <p>
                                                <b>Available HQC:</b> {planBalance}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                        <div style={{ 'padding-right': '10px', width: '55%' }}>
                                            <InputLabel
                                                label="Start"
                                                indicator={!unlimited ? 'required' : ''}
                                                title="The first date and time credits and the fair queuing multiplier will be refreshed"
                                            />
                                            <DatePicker
                                                key={
                                                    refreshStartDate !== undefined
                                                        ? refreshStartDate.toString() + '-refresh' + timeZone
                                                        : 'refresh'
                                                }
                                                type="datetime"
                                                timezone={timeZone}
                                                displayFormat="MM/DD/YY, h:mm a"
                                                placeholder="MM/DD/YY, h:mm a"
                                                minuteStep={minuteStep}
                                                onChange={handleRefreshStartDate}
                                                value={refreshStartDate}
                                                error={refreshStartDateError}
                                            />
                                        </div>
                                        <div style={{ 'padding-left': '10px' }}>
                                            <p>
                                                <b>Schedule: </b>
                                            </p>
                                            <p>
                                                <b title={moment.tz.guess(true)}>Local: </b>
                                                {!unlimited && refreshEnabled
                                                    ? getRefreshSchedule(refreshStartDate)
                                                    : 'None'}
                                                {timeZone !== 'UTC' ? (
                                                    <span>
                                                        <br />
                                                        <b title={'The time with a UTC offset of +00:00'}>UTC: </b>
                                                        {getRefreshSchedule(refreshStartDate, 'UTC')}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                                {timeZone != moment.tz.guess(true) && !unlimited && refreshEnabled ? (
                                                    <span>
                                                        <br />
                                                        <b title={timeZone}>{timeZone}: </b>
                                                        {getRefreshSchedule(refreshStartDate, timeZone)}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        <p>Not applicable on plans with unlimited credits.</p>
                    )}
                    {/*  only neesd to appear when refresh start  date is enabled, but it' still optional*/}
                    {refreshEnabled ? (
                        <div>
                            {!unlimited ? (
                                <div style={{ paddingBottom: 10 }}>
                                    {refreshEnabled ? (
                                        <div>
                                            <div>
                                                <InputLabel
                                                    label="End"
                                                    indicator={!unlimited ? 'optional' : ''}
                                                    title="The last date and time credits and the fair queuing multiplier will be refreshed"
                                                />

                                                <Checkbox
                                                    label="None"
                                                    checked={refreshStopMode === refreshEndModes.none}
                                                    onChange={() => handleRefreshEndMode(refreshEndModes.none)}
                                                />
                                                <Checkbox
                                                    label="By date"
                                                    disabled={refreshStartDate == ''}
                                                    checked={refreshStopMode === refreshEndModes.date}
                                                    onChange={() => handleRefreshEndMode(refreshEndModes.date)}
                                                />
                                                <Checkbox
                                                    label="By # of occurrences"
                                                    disabled={refreshStartDate == ''}
                                                    checked={refreshStopMode === refreshEndModes.occurrences}
                                                    onChange={() => handleRefreshEndMode(refreshEndModes.occurrences)}
                                                />
                                            </div>

                                            {!refreshStopMode == '' ? (
                                                <div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                        }}>
                                                        <div
                                                            style={{
                                                                'padding-right': '10px',

                                                                width: '55%',
                                                            }}>
                                                            {refreshStopMode == refreshEndModes.date ? (
                                                                <DatePicker
                                                                    key={
                                                                        refreshStopDate !== undefined
                                                                            ? refreshStopDate.toString() +
                                                                              '-refreshStop' +
                                                                              timeZone
                                                                            : 'refreshStop'
                                                                    }
                                                                    type="datetime"
                                                                    timezone={timeZone}
                                                                    displayFormat="MM/DD/YY, h:mm a"
                                                                    placeholder="MM/DD/YY, h:mm a"
                                                                    minuteStep={minuteStep}
                                                                    disablePast={true}
                                                                    onChange={handleRefreshStopDate}
                                                                    value={refreshStopDate}
                                                                    error={refreshStopDateError}
                                                                />
                                                            ) : (
                                                                <Input
                                                                    type="number"
                                                                    min="0"
                                                                    value={refreshOccurrences}
                                                                    onChange={(value) =>
                                                                        updateRefreshOccurrences(value)
                                                                    }
                                                                    error={refreshOccurrencesError}
                                                                />
                                                            )}
                                                        </div>
                                                        <div
                                                            style={{
                                                                paddingLeft: '10px',
                                                            }}>
                                                            <p>
                                                                <b>Last Refresh Date: </b>
                                                                {!unlimited
                                                                    ? getLastRefreshDate(
                                                                          refreshStopMode,
                                                                          refreshStartDate,
                                                                          refreshStopDate,
                                                                          refreshOccurrences,
                                                                          timeZone,
                                                                      )
                                                                    : 'None'}
                                                                {refreshStopMode == refreshEndModes.date &&
                                                                refreshEnabled ? (
                                                                    <div>
                                                                        <b># of Refreshes: </b>
                                                                        {getNumberOfRefreshes(
                                                                            refreshStartDate,
                                                                            refreshStopDate,
                                                                        ) || 0}
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    {/*Deactivation Date*/}
                    <div>
                        <InputLabel
                            label={'Deactivation Date'}
                            indicator={'optional'}
                            title="The date this plan will deactivate"
                        />
                        <p>
                            Should this plan <b>end</b> at a specific date/time? If <b>not</b>, the plan will remain
                            <b> active</b> indefinitely.
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}>
                            <Checkbox
                                label={scheduledEnd ? 'Yes' : 'No'}
                                toggle={true}
                                checked={scheduledEnd} //hasEndDate || software}
                                onChange={handleScheuledEnd}
                            />
                            {/* add some shortcuts*/}
                        </div>
                        {scheduledEnd ? (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    <div style={{ 'padding-right': '10px' }}>
                                        <DatePicker
                                            key={
                                                deactivationDate !== undefined
                                                    ? deactivationDate.toString() + '-expire' + timeZone
                                                    : 'expire'
                                            }
                                            type="datetime"
                                            timezone={timeZone}
                                            displayFormat="MM/DD/YY, h:mm a"
                                            placeholder="MM/DD/YY, h:mm a"
                                            minuteStep={minuteStep}
                                            disablePast={true}
                                            onChange={handleDeactivationDate}
                                            value={deactivationDate}
                                            error={deactivationDateError}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    {/*end of deactivation date*/}
                </Form.Group>
            </div>
        );
    }

    function showSoftwareForm() {
        return (
            <div>
                <Form.Group>
                    <h4>Software (Optional)</h4>
                    <p>Allow users within this organization to be granted to access Quantinuum software</p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <div
                            style={{
                                'padding-right': '20px',
                                width: '50%',
                            }}>
                            {generateSoftware()}
                        </div>

                        <div
                            style={{
                                'padding-right': '20px',
                                'padding-top': '30px',
                                width: '50%',
                                display: hasSoftware() ? '' : 'none',
                            }}>
                            {softwareName == 'inquanto' ? (
                                <a href={softwareProductLinks[softwareName]} target="_blank" title="View product page">
                                    <img className="logo" src={inquantoLogo}></img>
                                </a>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    {hasSoftware() && hasSoftwareLicense(softwareName) ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                paddingBottom: '10px',
                            }}>
                            <div>
                                <p>
                                    <Badge color="red">Important</Badge>{' '}
                                </p>
                                <ul>
                                    <li>
                                        This organization is <b>already</b> setup with access to this software
                                    </li>
                                    <li>
                                        Adding this software will <b>grant additional access</b> to the machines
                                        included on this plan
                                    </li>
                                    <li>
                                        Licensing details are <b>shared</b> across all plans. Please keep that in mind
                                        when making changes below
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {hasSoftware() ? (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <div style={{ 'padding-right': '10px' }}>
                                    <InputLabel
                                        label="# of Licenses:"
                                        indicator="required"
                                        title="The maximum number software licenses available for users"
                                    />
                                    <p>
                                        The maximum number software licenses that can be distributed to users in this
                                        organization
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    paddingTop: '10px',
                                }}>
                                {' '}
                                <div style={{ 'padding-right': '10px', width: '50%' }}>
                                    <Input
                                        type="number"
                                        min="0"
                                        value={licenses}
                                        onChange={(value) => updateLicenses(value)}
                                        error={licenseError}
                                    />
                                </div>
                                {hasSoftwareLicense(softwareName) ? (
                                    <div style={{ width: '50%' }}>
                                        <p>
                                            <b># of Licenses Assigned:</b> {getSoftwareLicense(softwareName)['users']}
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </Form.Group>
                {hasSoftware() ? (
                    <Form.Group>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <div style={{ 'padding-right': '10px' }}>
                                    <InputLabel
                                        label="Customer Type:"
                                        indicator="required"
                                        title="Dictates how user agreements are enforced"
                                    />
                                    <p>
                                        Dictates the level of compliance that should be enforced (or not) for all users
                                        in this organization{' '}
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                                <div
                                    style={{
                                        'padding-right': '20px',
                                        width: '50%',
                                    }}>
                                    {generateCustomerTypes()}
                                </div>
                                <div
                                    style={{
                                        'padding-right': '20px',

                                        width: '50%',
                                    }}>
                                    <p>{getCustomerTypeMessage(customerType)}</p>
                                </div>
                            </div>
                            {/*if there is alrelady license defined. Show this additional message*/}
                            {hasSoftwareLicense(softwareName) ? (
                                <p>
                                    <Badge color="blue">Note</Badge> If changed, <b>all users</b> in this organization
                                    will be asked to accept the corresponding EULA on their <b>next</b> login to ensure
                                    compliance
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                    </Form.Group>
                ) : (
                    ''
                )}
                <div
                    style={{
                        display: hasSoftware() && customerType != '' ? '' : 'none',
                    }}>
                    <div>
                        {licenseEndMode == licenseEndModes.date ? (
                            <Form.Group>
                                <InputLabel
                                    label="Expiration Date:"
                                    indicator="required"
                                    title="The date any issued licenses will expire"
                                />
                                <p>The date any and all issued software licenses will expire</p>
                                {/*if there is alrelady license defined. Show this additional message*/}

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    {/* add some shortcuts*/}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    <div style={{ 'padding-right': '10px' }}>
                                        <DatePicker
                                            key={
                                                softwareExpirationDate !== undefined
                                                    ? softwareExpirationDate.toString() + '-expire' + timeZone
                                                    : 'expire'
                                            }
                                            type="datetime"
                                            timezone={timeZone}
                                            displayFormat="MM/DD/YY, h:mm a"
                                            placeholder="MM/DD/YY, h:mm a"
                                            minuteStep={minuteStep}
                                            disablePast={true}
                                            onChange={handleSoftwareExpirationDate}
                                            value={softwareExpirationDate}
                                            error={softwareExpirationDateError}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            'padding-left': '10px',
                                            width: '100%',
                                        }}>
                                        <p>
                                            Time Zone: <b>{getTimeZoneSummary(timeZone)}</b>
                                        </p>
                                    </div>
                                </div>
                                {hasSoftwareLicense(softwareName) ? (
                                    <p>
                                        <Badge color="blue">Note</Badge> If changed, this will <b>adjust</b> the
                                        expiration date of any previously issued licenses
                                    </p>
                                ) : (
                                    ''
                                )}
                                {getExpirationMessage()}
                            </Form.Group>
                        ) : (
                            <Form.Group>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    <div
                                        style={{
                                            'padding-right': '20px',
                                            width: '100%',
                                        }}>
                                        <InputLabel
                                            label="Duration (Days):"
                                            indicator="required"
                                            title="The duration of the license "
                                        />
                                        <p>
                                            The length of time the license will last after the user has accepted the
                                            EULA
                                        </p>
                                        <Input
                                            type="number"
                                            min={getSoftwareDurationByCustomer(softwareName, customerType, 'min')}
                                            max={getSoftwareDurationByCustomer(softwareName, customerType, 'max')}
                                            value={softwareDuration}
                                            onChange={(value) => setSoftwareDuration(value)}
                                            error={softwareDurationError}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function showTagsForm() {
        return (
            <div>
                <Form.Group>
                    <h4>Tags (Optional)</h4>
                    <p>
                        Allow Quantinuum admin's to track and report on additional metadata on jobs submissions. Any
                        jobs submitted while the tag is active will be tagged with key-value pairs listed below.
                    </p>
                    <p>
                        <b>Please note</b>: Additional tags can be created by Quantinuum admin's on the <b>Settings</b>{' '}
                        page
                    </p>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                        <div
                            style={{
                                'padding-right': '10px',
                                width: '100%',
                            }}>
                            {/*{genTagOptions()}*/}
                            {generateTags()}
                            <p style={{ color: '#be271C', fontWeight: 600 }}>{tagError}</p>
                            <Button
                                type="primary"
                                onClick={addNewTag}
                                disabled={tagData.length == maxTags || getRemainingOptions().length == 0}
                                title={
                                    tagData.length == maxTags || getRemainingOptions().length == 0
                                        ? 'There are no more tags available to choose from'
                                        : ''
                                }>
                                <Icon root="common" name="tag" size="small"></Icon>
                                &nbsp;&nbsp;New Tag
                            </Button>

                            {props.allTags !== undefined && props.allTags.length > maxTags ? (
                                <p style={{ 'padding-top': '10px' }}>
                                    <b>Note:</b> You can have a maximum of <b>{maxTags}</b> tags.{' '}
                                    <b>{maxTags - tagData.length}</b> remaining{' '}
                                </p>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </Form.Group>
            </div>
        );
    }

    function genValidation(step) {
        let valid = true;

        if (step == steps.general) {
            valid = validateGeneralStep(true);
        } else if (step == steps.fairQueuing) {
            valid = validateFairQueuingStep(true);
        } else if (step == steps.dates) {
            valid = validateDateStep(true);
        } else if (step == steps.software) {
            valid = validateSoftwareStep(true);
        } else if (step == steps.tags) {
            valid = validateTags(true);
        }

        let color = 'red';
        let icon = 'badge-warning';
        let title = 'Missing or invalid information';
        if (valid) {
            color = 'green';
            icon = 'badge-check';
            title = 'Passed';
        }

        return (
            <span>
                <Icon root="common" name={icon} size="small" color={color} title={title} />
            </span>
        );
    }

    function getTagState(tag) {
        if (tag !== undefined) {
            if (editMode) {
                if ('state' in tag) {
                    if ('saved' in tag && tag['saved']) {
                        return tag['state'].toUpperCase();
                    } else {
                        if (tag['state'] == 'pending') {
                            return 'ADD';
                        } else {
                            return 'UPDATE';
                        }
                    }
                } else {
                    return 'UPDATE';
                }
            }
        }
    }

    function genTagsTable() {
        const content = [];

        let tableRows = [];

        if (!isNullOrEmpty(tagData)) {
            tagData.forEach((t, index) => {
                tableRows.push(
                    <tr>
                        <td>{t['key']}</td>
                        <td>{t['value']}</td>
                        {editMode ? <td>{getTagState(t)}</td> : ''}
                    </tr>,
                );
            });
        }

        content.push(
            <table class="table-summary">
                <tbody>
                    <tr>
                        <th width={125}>Key</th>
                        <th width={125}>Value</th>
                        {editMode ? <th width={125}>Action</th> : ''}
                    </tr>
                    {tableRows}
                </tbody>
            </table>,
        );

        return content;
    }

    function showFinalizedPlanForm() {
        return (
            <div>
                {!summaryMode ? <h3>Review & {editMode ? 'Update' : 'Create'}</h3> : ''}
                {!summaryMode ? (
                    <p>
                        Review the proposed plan one last time before saving your changes. Be sure to address any
                        sections with invalid or missing data
                    </p>
                ) : (
                    <p>View a detailed breakdown of all the attributes and features included in this plan</p>
                )}

                {showPlanSummary()}
            </div>
        );
    }

    function showPlanSummary() {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <div style={{ width: '100%' }}>
                        <Form.Group>
                            <h4>General {!summaryMode ? genValidation(steps.general) : ''}</h4>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <div style={{ width: '50%' }}>
                                    <table class="table-summary">
                                        <tbody>
                                            <tr>
                                                <td>Organization:</td>
                                                <td>
                                                    <b>{props.org}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Internal Features:</td>
                                                <td>
                                                    <b>{internal ? 'Yes' : 'No'}</b>
                                                </td>
                                            </tr>
                                            <tr style={{ display: promotional ? '' : 'none' }}>
                                                <td>Promotional Account:</td>
                                                <td>
                                                    <b>{promotional ? promotionalValue : 'No'}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <table class="table-summary">
                                        <tbody>
                                            <tr>
                                                <td>Machines:</td>
                                                <td>
                                                    <b>{machineNames.length > 0 ? machineNames.join(', ') : 'None'}</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Available HQC:</td>
                                                <td>
                                                    <b>{!unlimited ? planBalance : 'Unlimited'}</b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <div style={{ width: '100%' }}>
                        <Form.Group>
                            <h4>Fair Queuing {!summaryMode ? genValidation(steps.fairQueuing) : ''}</h4>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <div style={{ width: '50%' }}>
                                    <table class="table-summary">
                                        <tbody>
                                            <tr>
                                                <td>Multiplier (Active):</td>
                                                <td>
                                                    <b>{fqMultiplier}</b>
                                                </td>
                                            </tr>
                                            {extended ? (
                                                <tr>
                                                    <td> Multiplier (Throttled):</td>
                                                    <td>
                                                        <b>{fqMultiplierExtended}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <table class="table-summary">
                                        <tbody>
                                            <tr>
                                                <td> Extended Access:</td>
                                                <td>
                                                    <b>{extended ? 'Yes' : 'No'}</b>
                                                </td>
                                            </tr>
                                            {extended ? (
                                                <tr>
                                                    <td> Multiplier (Default):</td>
                                                    <td>
                                                        <b>{fqMultiplierDefault}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <div style={{ width: '100%' }}>
                        <Form.Group>
                            <h4>Dates & Schedule {!summaryMode ? genValidation(steps.dates) : ''}</h4>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}>
                                <div style={{ width: '50%' }}>
                                    <table class="table-summary">
                                        <tbody>
                                            <tr
                                                title={
                                                    createMode
                                                        ? 'The date this plan should be activated'
                                                        : 'The date this plan was activated'
                                                }>
                                                <td>Activation Date:</td>
                                                <td>
                                                    <b>
                                                        {renderActivationDate(
                                                            scheduledStart,
                                                            createDate,
                                                            activationDate,
                                                            enabled,
                                                            summaryMode,
                                                            timeZone,
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            {refreshEnabled && !unlimited ? (
                                                <tr title="The date this plan should start refreshing credits and other defaults">
                                                    <td>Refresh Start Date:</td>
                                                    <td>
                                                        <b>{renderDate(refreshStartDate, timeZone)}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                            {refreshEnabled && !unlimited ? (
                                                <tr title="The date that the last refresh cycle will actually run">
                                                    <td>Last Refresh Date:</td>
                                                    <td>
                                                        <b>
                                                            {getLastRefreshDate(
                                                                refreshStopMode,
                                                                refreshStartDate,
                                                                refreshStopDate,
                                                                refreshOccurrences,
                                                                timeZone,
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                            {refreshEnabled && !unlimited ? (
                                                <tr title="The date this plan should stop refreshing credits and other defaults">
                                                    <td>Refresh Stop Date:</td>
                                                    <td>
                                                        <b>{renderDate(refreshStopDate, timeZone)}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                            <tr title="The date this plan should end">
                                                <td>Deactivation Date:</td>
                                                <td>
                                                    <b>
                                                        {renderDeactivationDate(
                                                            scheduledEnd,
                                                            deactivationDate,
                                                            timeZone,
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>
                                            {summaryMode ? (
                                                <tr title="The date this plan was last updated">
                                                    <td>Last Updated:</td>
                                                    <td>
                                                        <b>{renderDate(lastUpdate, timeZone)}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <table class="table-summary">
                                        <tbody>
                                            <tr title="The time zone used to display the current dates">
                                                <td>Time Zone:</td>
                                                <td>
                                                    <b>{getTimeZoneSummary(timeZone)}</b>
                                                </td>
                                            </tr>
                                            {refreshEnabled && !unlimited ? (
                                                <tr>
                                                    <td>Monthly HQC Limit:</td>
                                                    <td>
                                                        <b>{monthlyBalance}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                            {refreshEnabled && !unlimited ? (
                                                <tr title="The frequency of when the plan be refreshed">
                                                    <td class="plan-date">Refresh Schedule:</td>
                                                    <td>
                                                        <b>{getRefreshSchedule(refreshStartDate, timeZone)}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                            {refreshEnabled && !unlimited ? (
                                                <tr title="The method of how the refresh cycle ends">
                                                    <td>Refresh Stop Mode:</td>
                                                    <td>
                                                        <b>{getRefreshStopModeSummary(refreshStopMode)}</b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}

                                            {refreshEnabled && !unlimited ? (
                                                <tr title="The total number of refreshes that are expected to run for this plan">
                                                    <td># of Refreshes:</td>
                                                    <td>
                                                        <b>
                                                            {getNumberOfRefreshesSummary(
                                                                refreshStopMode,
                                                                refreshStartDate,
                                                                refreshStopDate,
                                                                refreshOccurrences,
                                                                scheduledEnd,
                                                                deactivationDate,
                                                            )}
                                                        </b>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ''
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <div style={{ width: '100%' }}>
                        <Form.Group>
                            <h4>Software {!summaryMode ? genValidation(steps.software) : ''}</h4>
                            {hasSoftware() ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    <div style={{ width: '50%' }}>
                                        <table class="table-summary">
                                            <tbody>
                                                <tr>
                                                    <td>Name</td>
                                                    <td>
                                                        <b>{softwareMap[softwareName]}</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Customer Type:</td>
                                                    <td>
                                                        <b>{customerTypes[customerType] || 'Unknown'}</b>
                                                    </td>
                                                </tr>
                                                {licenseEndMode == licenseEndModes.date ? (
                                                    <tr>
                                                        <td>Time Remaining:</td>
                                                        <td>
                                                            <b>
                                                                {getTimeRemainingInDays(softwareExpirationDate)} day(s)
                                                            </b>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    ''
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <table class="table-summary">
                                            <tbody>
                                                <tr>
                                                    <td># of Licenses:</td>
                                                    <td>
                                                        <b>{licenses || 0}</b>
                                                    </td>
                                                </tr>
                                                {licenseEndMode == licenseEndModes.date ? (
                                                    <tr>
                                                        <td>Expiration Date:</td>
                                                        <td>
                                                            <b> {renderDate(softwareExpirationDate, timeZone)}</b>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td>Duration:</td>
                                                        <td>
                                                            <b>{softwareDuration} day(s)</b>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                <b>None</b>
                            )}
                        </Form.Group>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                    <div style={{ width: '100%' }}>
                        <Form.Group>
                            <h4>Tags {!summaryMode ? genValidation(steps.tags) : ''}</h4>
                            {tagData.length > 0 ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    {genTagsTable()}
                                </div>
                            ) : (
                                <b>None</b>
                            )}
                        </Form.Group>
                    </div>
                </div>
            </div>
        );
    }

    function nextStep() {
        //validate the step we're on

        let validated = validateStep(step, false);

        if (validated) {
            let nextStep = step + 1;
            setStep(nextStep);
        }
    }

    function previousStep() {
        let previousStep = step - 1;
        setStep(previousStep);
    }

    function validateGeneralStep(excludeErrors) {
        let valid = true;

        let machineErrorMsg = '';
        let planBalanceMsg = '';
        let internalErrorMsg = '';
        let promotionalErrorMSg = '';

        if (machineNames.length == 0) {
            valid = false;
            machineErrorMsg = 'Machine names are required';
        }

        // make sure user is not mixing SIM targets with real machines
        const machineTotal = machineNames.length;
        let simTargetCount = 0;
        machineNames.forEach((machine) => {
            if (isEmulator(machine)) {
                simTargetCount++;
            }
        });

        if (simTargetCount > 0 && simTargetCount < machineTotal) {
            valid = false;
            machineErrorMsg = "You can't combine SIM targets with other targets in the same plan!";
        }

        if (!unlimited) {
            if (planBalance < 0) {
                planBalanceMsg = 'Must be 0 or greater';
                valid = false;
            }
        }

        if (promotional) {
            if (promotionalValue === undefined) {
                promotionalErrorMSg = 'Promotional Account value is required';
                valid = false;
            }
        }

        // adding this exclusion step that we can render on the correct step and also on the summary page
        // without it it was throwing a blank page
        if (!excludeErrors) {
            setMachineError(machineErrorMsg);
            setInternalError(internalErrorMsg);
            setPlanBalanceError(planBalanceMsg);
            setPromotionalError(promotionalErrorMSg);
        }

        //setIsGeneralValid(valid)

        return valid;
    }

    function validateFairQueuingStep(excludeErrors) {
        let valid = true;

        let fqMsg = '';
        let fqDefaultMsg = '';
        let fqExtendedMsg = '';

        if (fqMultiplier <= 0) {
            valid = false;
            fqMsg = 'Must be a positive number';
        }

        if (extended) {
            if (fqMultiplierDefault <= 0) {
                valid = false;
                fqDefaultMsg = 'Must be a positive number';
            }
            if (fqMultiplierExtended <= 0) {
                valid = false;
                fqExtendedMsg = 'Must be a positive number';

                //extended must be greater than active multiplier
            } else if (fqMultiplierExtended <= fqMultiplierDefault) {
                //make sure the fq multiplier extended is greater than the default when throttling is enabled
                valid = false;
                fqDefaultMsg = 'Must be less than throttled multiplier';
            }
        }

        // adding this exclusion step that we can render on the correct step and also on the summary page
        // without it it was throwing a blank page
        if (!excludeErrors) {
            setFQError(fqMsg);
            setFQErrorDefault(fqDefaultMsg);
            setFQErrorExtended(fqExtendedMsg);
        }

        //setIsFairQueuingValid(valid)

        return valid;
    }

    function validateDateStep(excludeErrors) {
        let valid = true;

        let startDateMsg = '';
        let expirationDateMsg = '';
        let refreshStartDateMsg = '';
        let refreshStopDateMsg = '';
        let refreshOccurrencesMsg = '';
        let monthlyBalanceMsg = '';
        let refreshEnabledMsg = '';

        //if the plan has a scheduled start date
        if (scheduledStart && !activationDate) {
            valid = false;
            startDateMsg = 'Missing required date';
        }

        if (!refreshEnabled && extended) {
            refreshEnabledMsg = 'A refresh schedule must be defined when extended access is enabled';
            valid = false;
        }

        //if the plan has a scheduled end date
        if (scheduledEnd) {
            if (!deactivationDate) {
                valid = false;
                expirationDateMsg = 'Missing required date';
            } else {
                if (scheduledStart && activationDate) {
                    if (moment(deactivationDate).isSameOrBefore(moment(activationDate))) {
                        valid = false;
                        expirationDateMsg = 'Must be after activation date';
                    }
                }

                if (refreshEnabled) {
                    if (refreshStopMode == refreshEndModes.date && refreshStopDate) {
                        let lastRefreshDate = getLastRefreshDate(
                            refreshStopMode,
                            refreshStartDate,
                            refreshStopDate,
                            refreshOccurrences,
                        );
                        if (moment(deactivationDate).isSameOrBefore(moment(lastRefreshDate))) {
                            valid = false;
                            expirationDateMsg = 'Must be after refresh stop date';
                        }
                    }

                    if (refreshStopMode == refreshEndModes.occurrences && refreshOccurrences > 0) {
                        let lastRefreshDate = getLastRefreshDate(
                            refreshStopMode,
                            refreshStartDate,
                            refreshStopDate,
                            refreshOccurrences,
                        );
                        if (moment(deactivationDate).isSameOrBefore(moment(lastRefreshDate))) {
                            valid = false;
                            refreshOccurrencesMsg = 'Last refresh date must be before deactivation date';
                        }
                    }
                }
            }
        }

        if (!unlimited && refreshEnabled) {
            if (monthlyBalance == undefined || monthlyBalance <= 0) {
                monthlyBalanceMsg = 'Must be greater than 0';
                valid = false;
            }

            //if the plan has a refresh date (should we defualt to 1st of month)
            if (!refreshStartDate) {
                valid = false;
                refreshStartDateMsg = 'Missing required date';
            } else {
                //has the refresh date, make sure it's after the activation date and before the deactivation date

                if (scheduledStart && activationDate) {
                    if (moment(refreshStartDate).isSameOrBefore(moment(activationDate))) {
                        valid = false;
                        refreshStartDateMsg = 'Must be after activation date';
                    }
                }

                if (scheduledEnd && deactivationDate) {
                    if (moment(refreshStartDate).isSameOrAfter(moment(deactivationDate))) {
                        valid = false;
                        refreshStartDateMsg = 'Must be before deactivation date';
                    }
                }

                //consider the timezone, otherwise we create problems if the user picks a non-local timezone
                if (moment(refreshStartDate).tz(timeZone).date() >= 29) {
                    refreshStartDateMsg = 'Must be in first 28 days';
                    valid = false;
                }
            }

            if (refreshStopMode !== refreshEndModes.none) {
                //if the plan has a refresh stop date\
                if (refreshStopMode == refreshEndModes.date) {
                    if (!refreshStopDate) {
                        valid = false;
                        refreshStopDateMsg = 'Missing required date';
                    } else {
                        if (scheduledStart && activationDate) {
                            if (!moment(refreshStopDate).isSameOrAfter(moment(activationDate))) {
                                valid = false;
                                refreshStopDateMsg = 'Must be after activation date';
                            }
                        }

                        if (scheduledEnd && deactivationDate) {
                            if (moment(refreshStopDate).isSameOrAfter(moment(deactivationDate))) {
                                valid = false;
                                refreshStopDateMsg = 'Must be before deactivation date';
                            }
                        }

                        if (refreshStartDate) {
                            if (!moment(refreshStopDate).isSameOrAfter(moment(refreshStartDate))) {
                                valid = false;
                                refreshStopDateMsg = 'Must be after refresh date';
                            }
                        }
                    }
                } else {
                    if (refreshOccurrences <= 0) {
                        valid = false;
                        refreshOccurrencesMsg = 'Must be greater than 0';
                    }
                }
            }
        }

        if (!excludeErrors) {
            setMonthlyBalanceError(monthlyBalanceMsg);
            setActivationDateError(startDateMsg);
            setDeactivationDateError(expirationDateMsg);
            setRefreshStartDateError(refreshStartDateMsg);
            setRefreshStopDateError(refreshStopDateMsg);
            setRefreshOccurrencesError(refreshOccurrencesMsg);
            setRefreshEnabledError(refreshEnabledMsg);
        }

        return valid;
    }

    function validateSoftwareStep(excludeErrors) {
        let valid = true;

        let licenseMsg = '';
        let customerMsg = '';
        let durationMsg = '';
        let expirationMsg = '';

        if (hasSoftware()) {
            //make sure we have licenses

            if (licenses <= 0) {
                valid = false;
                licenseMsg = 'Must be greater than 0';
            }

            if (customerType == undefined || customerType == '') {
                valid = false;
                customerMsg = 'Missing required customer type';
            }

            //must have a expiration or duration
            if (customerType == 'trial') {
                if (softwareDuration <= 0) {
                    valid = false;
                    durationMsg = 'Must be greater than 0';
                }
            } else {
                //reseller/beta/direct
                if (!softwareExpirationDate) {
                    valid = false;
                    expirationMsg = 'Missing required date';
                } else {
                    if (scheduledEnd && deactivationDate) {
                        if (moment(softwareExpirationDate).isAfter(moment(deactivationDate))) {
                            valid = false;
                            expirationMsg = 'Must be before or same as deactivation date';
                        }
                    }
                }
            }
        }

        if (!excludeErrors) {
            setLicenseError(licenseMsg);
            setCustomerTypeError(customerMsg);
            setSoftwareDurationError(durationMsg);
            setSoftwareExpirationDateError(expirationMsg);
        }

        return valid;
    }

    function validateTags(excludeErrors) {
        let valid = true;

        tagData.forEach((t) => {
            if (t['key'].trim().length == 0) {
                valid = false;
                if (!excludeErrors) {
                    setTagError('One or more tags are missing a key');
                }
            } else if (t['value'].trim().length == 0) {
                valid = false;
                if (!excludeErrors) {
                    setTagError('One or more tags are missing a value');
                }
            }
        });

        return valid;
    }

    function validateTagKeys(tags, excludeErrors) {
        let valid = true;

        //create a set of the keys
        let keys = new Set();
        tags.forEach((t) => {
            if (keys.has(t['key'])) {
                t['error'] = 'Key already being used';
                valid = false;
            } else {
                t['error'] = '';
            }

            if ('state' in t && t['state'] == 'pending') {
                if ((t['key'] = '')) {
                    t['error'] = 'Missing key';
                    valid = false;
                }
            }

            // add this key to the list of known keys if it doesn't have it and it's not empty
            if (!keys.has(t['key']) && t['key'] !== '') {
                keys.add(t['key']);
            }
        });

        return valid;
    }

    function validateTagValues(tags, excludeErrors) {
        let valid = true;
        tags.forEach((t) => {
            if ('state' in t && t['state'] == 'pending') {
                if ((t['value'] = '')) {
                    t['error'] = 'Missing value';
                    valid = false;
                }
            }
        });

        return valid;
    }

    function validateStep(step, excludeErrors) {
        let valid = true;

        if (step == steps.general) {
            valid = validateGeneralStep(excludeErrors);
        } else if (step == steps.fairQueuing) {
            valid = validateFairQueuingStep(excludeErrors);
        } else if (step == steps.dates) {
            valid = validateDateStep(excludeErrors);
        } else if (step == steps.software) {
            valid = validateSoftwareStep(excludeErrors);
        } else if (step == steps.tags) {
            valid = validateTags(excludeErrors);
        }
        return valid;
    }

    function validateAllSteps() {
        let valid = true;
        let invalidSteps = [];

        Object.keys(steps).forEach((s) => {
            //get setp from
            let stepValue = steps[s];
            valid = validateStep(stepValue, true);
            if (!valid) {
                invalidSteps.push(s);
            }
        });

        if (invalidSteps.length > 0) {
            return false;
        } else {
            return true; //valid
        }
    }

    function renderMode() {
        if (createMode) {
            return (
                <Button type="primary" size="small" onClick={handleShow}>
                    <Icon root="common" name="slidercontrols-plus" size="small"></Icon>
                    &nbsp;&nbsp;Add Plan
                </Button>
            );
        } else if (editMode) {
            return (
                <div onClick={handleShow}>
                    <Icon style={{ cursor: 'pointer' }} root="common" name="edit" size="small"></Icon>
                    &nbsp;{props.children || 'Edit'}
                </div>
            );
        } else {
            return (
                <div onClick={handleShow}>
                    <Icon style={{ cursor: 'pointer' }} root="common" name="document-report" size="small"></Icon>
                    &nbsp;{props.children || 'Summary'}
                </div>
            );
        }
    }

    function createOrEditModeForms() {
        return (
            <Tab activeIndex={step}>
                <Tab.Pane
                    title={
                        <Icon root="common" name="display-adjust" size="small" onClick={() => setStep(steps.general)}>
                            &nbsp;General
                        </Icon>
                    }>
                    {showGeneralForm()}
                </Tab.Pane>
                <Tab.Pane
                    title={
                        <Icon root="common" name="descending" size="small" onClick={() => setStep(steps.fairQueuing)}>
                            &nbsp;Fair Queuing
                        </Icon>
                    }>
                    {showFairQueuingForm()}
                </Tab.Pane>

                <Tab.Pane
                    title={
                        <Icon root="common" name="calendar" size="small" onClick={() => setStep(steps.dates)}>
                            &nbsp;Dates & Schedule
                        </Icon>
                    }>
                    {showDatesForm()}
                </Tab.Pane>
                <Tab.Pane
                    title={
                        <Icon root="common" name="user-settings" size="small" onClick={() => setStep(steps.software)}>
                            &nbsp;Software
                        </Icon>
                    }>
                    {showSoftwareForm()}
                </Tab.Pane>
                <Tab.Pane
                    title={
                        <Icon root="common" name="tag" size="small" onClick={() => setStep(steps.tags)}>
                            &nbsp;Tags
                        </Icon>
                    }>
                    {showTagsForm()}
                </Tab.Pane>
                <Tab.Pane
                    title={
                        <Icon root="common" name="document-confirm" size="small" onClick={() => setStep(steps.review)}>
                            &nbsp;Review & {editMode ? 'Update' : 'Create'}
                        </Icon>
                    }>
                    {showFinalizedPlanForm()}
                </Tab.Pane>
            </Tab>
        );
    }

    function renderModeContent() {
        if (createMode || editMode) {
            return createOrEditModeForms();
        } else {
            return showFinalizedPlanForm();
        }
    }

    function renderModeHeader() {
        if (createMode) {
            return (
                <div className="hqs-umui-modal-header ">
                    <Icon root="common" name="document-new" size="medium"></Icon>
                    &nbsp;&nbsp;Add Plan
                </div>
            );
        } else if (editMode) {
            return (
                <div className="hqs-umui-modal-header ">
                    <Icon root="common" name="document-edit" size="medium"></Icon>
                    &nbsp;&nbsp;Edit Plan
                </div>
            );
        } else {
            //summary
            return (
                <div className="hqs-umui-modal-header ">
                    <Icon root="common" name="document-report" size="medium"></Icon>
                    &nbsp;&nbsp;Plan Summary
                </div>
            );
        }
    }

    function renderModeFooter() {
        const element = [];

        if (createMode || editMode) {
            if (step > 0) {
                element.push(
                    <Button type="primary" onClick={() => previousStep()}>
                        Previous
                    </Button>,
                );
            }

            if (step < steps.review) {
                element.push(
                    <Button type="primary" onClick={() => nextStep()}>
                        Next
                    </Button>,
                );
            } else {
                element.push(
                    <Button type="primary" onClick={() => handleAdd()} disabled={!validateAllSteps()}>
                        {createMode ? 'Create Plan' : 'Update Plan'}
                    </Button>,
                );
            }
        }

        return element;
    }

    return (
        <div>
            {renderMode()}

            <Modal
                open={show}
                onClose={handleClose}
                size="small"
                style={{
                    padding: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: '850px',
                    maxHeight: '900px',
                    overflowY: 'auto',
                }}
                className="any-plan-modal-form">
                <Modal.Header>{renderModeHeader()}</Modal.Header>
                <Modal.Content scrolling={false}>{renderModeContent()}</Modal.Content>
                <Modal.Footer>
                    <div>
                        <Button type="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        {renderModeFooter()}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ManageAnyPlanForm;
